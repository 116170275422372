import {yupResolver} from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Box, Button, Grid, Typography} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {makeStyles} from '@mui/styles';
import React, {useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import modeloTareaEnumerators from 'src/modules/modeloTarea/modeloTareaEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import SubTareaService from 'src/modules/subTarea/subTareaService';
import userEnumerators from 'src/modules/user/userEnumerators';
import Storage from 'src/security/storage';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import CategoriaAutocompleteFormItem from 'src/view/categoria/autocomplete/CategoriaAutocompleteFormItem';
import HabilidadAutocompleteFormItem from 'src/view/habilidad/autocomplete/HabilidadAutocompleteFormItem';
import ModeloFormularioAutocompleteFormItem from 'src/view/modeloFormulario/autocomplete/ModeloFormularioAutocompleteFormItem';
import ObjetivoAutocompleteFormItem from 'src/view/objetivo/autocomplete/ObjetivoAutocompleteFormItem';
import ProductoAutocompleteFormItem from 'src/view/producto/autocomplete/ProductoAutocompleteFormItem';
import PuestoAutocompleteFormItem from 'src/view/puesto/autocomplete/PuestoAutocompleteFormItem';
import RecursoAutocompleteFormItem from 'src/view/recurso/autocomplete/RecursoAutocompleteFormItem';
import RecursoTableDetails from 'src/view/recurso/list/RecursoTableDetails';
import AutocompleteWithList from 'src/view/shared/form/items/AutocompleteWithList';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import RadioFormItem from 'src/view/shared/form/items/RadioFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import SwitchFormItem from 'src/view/shared/form/items/SwitchFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import SubTareaFormModal from 'src/view/subTarea/form/SubTareaFormModal';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';
const schema = yup.object().shape({
  titulo: yupFormSchemas.string(i18n('entities.modeloTarea.fields.titulo'), {
    required: true,
  }),
  descripcion: yupFormSchemas.string(i18n('entities.modeloTarea.fields.descripcion'), {
    required: false,
  }),
  puntaje: yupFormSchemas.integer(i18n('entities.modeloTarea.fields.puntaje'), {
    required: false,
  }),
  tienePuntaje: yupFormSchemas.boolean(i18n('entities.modeloTarea.fields.tienePuntaje'), {}),
  automatizada: yupFormSchemas.boolean(i18n('entities.modeloTarea.fields.automatizada'), {}),
  tipoVisualizacion: yupFormSchemas.enumerator(i18n('entities.modeloTarea.fields.tipoVisualizacion'), {
    required: true,
    options: modeloTareaEnumerators.tipoVisualizacion,
  }),
  subTareasRelacionadas: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.subTareasRelacionadas'), {}),
  productosRelacionados: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.productosRelacionados'), {}),
  objetivo: yupFormSchemas.relationToOne(i18n('entities.modeloTarea.fields.objetivo'), {}),
  sucursales: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.sucursales'), {}),
  categoria: yupFormSchemas.relationToOne(i18n('entities.modeloTarea.fields.categoria'), {
    required: false,
  }),
  procedimiento: yupFormSchemas.relationToOne(i18n('entities.modeloTarea.fields.procedimiento'), {
    required: false,
  }),
  habilidades: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.habilidades'), {
    required: false,
  }),
  documentosAdjuntos: yupFormSchemas.files(i18n('entities.modeloTarea.fields.documentosAdjuntos'), {}),
  fotosAdjuntas: yupFormSchemas.images(i18n('entities.modeloTarea.fields.fotosAdjuntas'), {
    required: false,
  }),
  esEvaluada: yupFormSchemas.boolean(i18n('entities.modeloTarea.fields.esEvaluada'), {}),
  responsables: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.responsables'), {}),
  asignarFunciones: yupFormSchemas.boolean(i18n('entities.modeloTarea.fields.asignarFunciones'), {}),
  asignadorFunciones: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.asignadorFunciones'), {}),
  incluirAsignacionEconomica: yupFormSchemas.boolean(i18n('entities.modeloTarea.fields.incluirAsignacionEconomica'), {}),
  asignacionEconomica: yupFormSchemas.string(i18n('entities.modeloTarea.fields.asignacionEconomica'), {}),

  tipoProcedimiento: yupFormSchemas.enumerator(i18n('entities.modeloTarea.fields.tipoProcedimiento'), {
    required: false,
    options: modeloTareaEnumerators.tipoProcedimiento,
  }),
  evaluados: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.evaluados'), {}),
  encuestados: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.encuestados'), {}),
  evaluadores: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.evaluadores'), {}),

  recursosNecesarios: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.recursosNecesarios'), {}),
  prioridad: yupFormSchemas.enumerator(i18n('entities.modeloTarea.fields.prioridad'), {
    required: false,
    options: modeloTareaEnumerators.prioridad,
  }),
  certificacion: yupFormSchemas.string(i18n('entities.modeloTarea.fields.certificacion'), {}),
  restricts: yupFormSchemas.generic(i18n('entities.modeloTarea.fields.restricts')),
  createdFromLibrary: yupFormSchemas.boolean(i18n('entities.modeloTarea.fields.createdFromLibrary'), {}),
  locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
  notifyUserGroup: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.notifyUserGroup'), {}),
  modifyUserGroup: yupFormSchemas.relationToMany(i18n('entities.modeloTarea.fields.modifyUserGroup'), {}),
});

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

function TabPanel(props) {
  const {children, value, index, simpleView, ...other} = props;

  return (
    <Box
      sx={
        {
          ...magicFlexBox,
          display: value !== index ? 'none' : 'flex',
        } as any
      }
      role="tabpanel"
      style={{width: '100%', marginTop: simpleView ? -30 : undefined}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={
            {
              ...magicFlexBox,
              overflow: 'auto',
            } as any
          }
          p={0}
          pt={2}>
          {children}
        </Box>
      )}
    </Box>
  );
}

function ModeloTareaForm(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const [tabValue, setTabValue] = useState(0);

  const [initialValues] = useState(() => {
    const record = props.record || props.defaultValues || {};

    // has key createdFromLibrary
    const hasCreatedFromLibrary = Object.prototype.hasOwnProperty.call(record, 'createdFromLibrary');

    return {
      //Stem
      createdFromLibrary: hasCreatedFromLibrary ? record.createdFromLibrary : true,
      tipoProcedimiento: record.tipoProcedimiento,
      evaluados: record.evaluados || [],
      encuestados: record.encuestados || [],
      evaluadores: record.evaluadores || [],
      recursosNecesarios: record.recursosNecesarios || [],
      titulo: record.titulo,
      procedimiento: record.procedimiento,
      descripcion: record.descripcion,
      tienePuntaje: record.tienePuntaje,
      puntaje: record.puntaje,
      automatizada: record.automatizada,
      tipoVisualizacion: record.tipoVisualizacion,
      subTareasRelacionadas: record.subTareasRelacionadas || [],
      productosRelacionados: record.productosRelacionados || [],
      objetivo: record.objetivo,
      sucursales: record.sucursales || [],
      categoria: record.categoria,
      habilidades: record.habilidades,
      documentosAdjuntos: record.documentosAdjuntos || [],
      fotosAdjuntas: record.fotosAdjuntas || [],
      esEvaluada: record.esEvaluada,
      responsables: record.responsables || [],
      asignarFunciones: record.asignarFunciones,
      asignadorFunciones: record.asignadorFunciones || [],
      incluirAsignacionEconomica: record.incluirAsignacionEconomica,
      asignacionEconomica: record.asignacionEconomica,
      prioridad: record.prioridad,
      certificacion: record.certificacion,
      restricts: record.restricts || {
        enabled: false,
        roles: [],
        users: [],
        positions: [],
      },
      appFields: record.appFields || {
        enabled: false,
        planification: false,
        assets: false,
        currentUserAssign: false,
        currentUserValidate: false,
        validators: false,
        inheritParentTaskValidators: false,
        startDate: false,
        endDate: false,
      },
      locations: record.locations || userLocations || [],
      notifyUserGroup: record.notifyUserGroup || [],
      modifyUserGroup: record.modifyUserGroup || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });
  const {control, register} = form;
  register('titulo');
  const watchTitulo: any = useWatch({name: 'titulo', control});
  const watchTipoVisualizacion: any = useWatch({name: 'tipoVisualizacion', control});
  const watchProcedimiento: any = useWatch({name: 'procedimiento', control});
  console.log('%c⧭', 'color: #5200cc', {watchProcedimiento});
  const watchTienePuntaje: any = useWatch({name: 'tienePuntaje', control});
  const watchRecursosNecesarios: any = useWatch({name: 'recursosNecesarios', control});

  const onSubmit = (values) => {
    // if (values.tipoVisualizacion !== 'Procedimiento' && values.habilidades?.length === 0) {
    //   Message.error('Indique las habilidades asociadas');
    //   return;
    // }

    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  function a11yProps(index) {
    return {
      id: `modeloTarea-tab-${index}`,
      'aria-controls': `modeloTarea-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {saveLoading, modal, simpleView} = props;
  //console.log('%c⧭', 'color: #ffcc00', {simpleView});
  const classes = useStyles();

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
            } as any
          }>
          <Box sx={{...magicFlexBox} as any}>
            <Box sx={{...magicFlexBox, overflow: 'auto'} as any}>
              <Typography variant="h1" sx={{mb: 3}}>
                {watchTitulo}
              </Typography>

              {/* {!simpleView && (
                <div style={{marginBottom: 15}}>
                  <QRCode value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/modelo-tarea/${props.record?.id}`} size={140} />
                </div>
              )} */}

              {!simpleView && (
                <Tabs
                  orientation="horizontal"
                  variant="scrollable"
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="modeloTarea-wizard"
                  textColor="secondary"
                  indicatorColor="secondary"
                  sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                  }}>
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Recursos" {...a11yProps(1)} />
                  <Tab label="Restricciones" {...a11yProps(2)} />
                  <Tab label="Campos Avanzados" {...a11yProps(3)} />
                </Tabs>
              )}
              <TabPanel value={tabValue} index={3} simpleView={simpleView}>
                {/* False Comment 0.0.1 */}
                <Grid container>
                  <Grid item lg={12} style={{marginBottom: 15}}>
                    <SwitchFormItem
                      name="appFields.enabled"
                      disabled={props.disabled}
                      label={i18n('entities.modeloTarea.appFields.enabled')}
                    />
                  </Grid>
                  {form.watch('appFields.enabled') && (
                    <Grid container>
                      {/* <Grid item lg={12} style={{marginTop: 15}}>
                      <SwitchFormItem name="appFields.createNewTask" disabled={props.disabled} label={i18n('entities.modeloTarea.appFields.createNewTask')} />
                    </Grid> */}
                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.addActivity"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.addActivity')}
                        />
                      </Grid>
                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.planification"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.planification')}
                        />
                      </Grid>
                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.assets"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.assets')}
                        />
                      </Grid>
                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.currentUserAssign"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.currentUserAssign')}
                        />
                      </Grid>

                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.currentUserValidate"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.currentUserValidate')}
                        />
                      </Grid>

                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.validators"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.validators')}
                        />
                      </Grid>

                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.inheritParentTaskValidators"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.inheritParentTaskValidators')}
                        />
                      </Grid>

                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.startDate"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.startDate')}
                        />
                      </Grid>

                      <Grid item lg={12} style={{marginTop: 15}}>
                        <SwitchFormItem
                          name="appFields.endDate"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.appFields.endDate')}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2} simpleView={simpleView}>
                <Grid container>
                  <Grid item lg={12}>
                    <SwitchFormItem
                      name="restricts.enabled"
                      disabled={props.disabled}
                      label={i18n('entities.modeloTarea.restricts.enabled')}
                    />
                  </Grid>
                  {form.watch('restricts.enabled') && (
                    <>
                      <Grid item lg={12}>
                        <br></br>
                        <strong>Sólo pueden ver la tarea los siguientes:</strong>
                      </Grid>
                      <Grid item lg={12}>
                        <SelectFormItem
                          name="restricts.roles"
                          label={i18n('entities.modeloTarea.restricts.roles')}
                          options={userEnumerators.roles.map((value) => ({
                            value,
                            label: i18n(`roles.${value}.label`),
                          }))}
                          mode="multiple"
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <UserAutocompleteFormItem
                          name="restricts.users"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.restricts.users')}
                          required={false}
                          showCreate={!props.modal}
                          mode="multiple"
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <PuestoAutocompleteFormItem
                          name="restricts.positions"
                          label={i18n('entities.modeloTarea.restricts.positions')}
                          required={false}
                          showCreate={!props.modal}
                          mode="multiple"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </TabPanel>

              {!(props.hideFormWhenReady && simpleView && props.disabled) && (
                <TabPanel value={tabValue} index={0} simpleView={simpleView}>
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                      {/* <input name="titulo" /> */}
                      <InputFormItem
                        name="titulo"
                        disabled={props.disabled}
                        label={i18n('entities.modeloTarea.fields.titulo')}
                        required={true}
                        autoFocus
                      />
                    </Grid>

                    {!simpleView && (
                      <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                        <InputFormItem
                          name="certificacion"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.fields.certificacion')}
                        />
                      </Grid>
                    )}

                    <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                      <SelectFormItem
                        name="prioridad"
                        disabled={props.disabled}
                        label={i18n('entities.modeloTarea.fields.prioridad')}
                        required={false}
                        options={modeloTareaEnumerators.prioridad.map((item) => ({
                          value: item,
                          label: i18n(`entities.modeloTarea.enumerators.prioridad.${item}`),
                        }))}
                      />
                    </Grid>

                    <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                      <TextAreaFormItem
                        name="descripcion"
                        disabled={props.disabled}
                        label={i18n('entities.modeloTarea.fields.descripcion')}
                        required={false}
                        autoFocus
                        rows={6}
                        noAnswerText={' '}
                      />
                    </Grid>

                    <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                      <UbicacionAutocompleteFormItem
                        name="locations"
                        disabled={props.disabled}
                        label={i18n('common.locations')}
                        required={false}
                        showCreate={false}
                        mode="multiple"
                      />
                    </Grid>

                    {!simpleView && (
                      <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                        <RadioFormItem
                          name="tipoVisualizacion"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.fields.tipoVisualizacion')}
                          options={modeloTareaEnumerators.tipoVisualizacion.map((value) => ({
                            value,
                            label: i18n(`entities.modeloTarea.enumerators.tipoVisualizacion.${value}`),
                          }))}
                          required={true}
                        />
                      </Grid>
                    )}

                    {watchTipoVisualizacion !== 'Procedimiento' && (
                      <React.Fragment>
                        <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                          <SwitchFormItem
                            name="tienePuntaje"
                            disabled={props.disabled}
                            label={i18n('entities.modeloTarea.fields.tienePuntaje')}
                          />
                        </Grid>
                        {watchTienePuntaje ? (
                          <InputNumberFormItem
                            name="puntaje"
                            disabled={props.disabled}
                            label={i18n('entities.modeloTarea.fields.puntaje')}
                            required={true}
                            autoFocus
                          />
                        ) : null}
                      </React.Fragment>
                    )}

                    {watchTipoVisualizacion === 'Pasos' || watchTipoVisualizacion === 'Checklist' ? (
                      <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                        <AutocompleteWithList
                          name="subTareasRelacionadas"
                          label={i18n('entities.modeloTarea.fields.subTareasRelacionadas')}
                          required={false}
                          showCreate={!props.modal}
                          disabled={props.disabled}
                          labelFn={(record) => {
                            return record.label || record.nombre;
                          }}
                          apiService={{
                            listAutocomplete: SubTareaService.listAutocomplete,
                            create: SubTareaService.create,
                            update: SubTareaService.update,
                          }}
                          defaultValue={initialValues?.subTareasRelacionadas}
                          FormModal={SubTareaFormModal}
                          simpleEdit={'nombre'}
                          modelName={'subTarea'}
                        />
                      </Grid>
                    ) : null}

                    {watchTipoVisualizacion === 'Ciclico1' ? (
                      <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                        <ProductoAutocompleteFormItem
                          name="productosRelacionados"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.fields.productosRelacionados')}
                          required={false}
                          showCreate={!props.modal}
                          mode="multiple"
                        />
                      </Grid>
                    ) : null}

                    {watchTipoVisualizacion === 'Procedimiento' && (
                      <React.Fragment>
                        {!simpleView && (
                          <ModeloFormularioAutocompleteFormItem
                            name="procedimiento"
                            disabled={props.disabled}
                            label={i18n('entities.modeloTarea.fields.procedimiento')}
                            required={false}
                            showCreate={!props.modal}
                          />
                        )}

                        <Grid item lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                          <SelectFormItem
                            name="tipoProcedimiento"
                            disabled={props.disabled}
                            label={i18n('entities.modeloTarea.fields.tipoProcedimiento')}
                            options={modeloTareaEnumerators.tipoProcedimiento.map((value) => ({
                              value,
                              label: i18n(`entities.modeloTarea.enumerators.tipoProcedimiento.${value}`),
                            }))}
                            required={true}
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    {watchTipoVisualizacion === 'Procedimiento' &&
                      watchProcedimiento &&
                      watchProcedimiento.clasificacion === 'incidente' && (
                        <React.Fragment>
                          <Grid item lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                            <UserAutocompleteFormItem
                              name="notifyUserGroup"
                              disabled={props.disabled}
                              label={i18n('entities.modeloTarea.fields.notifyUserGroup')}
                              required={false}
                              showCreate={!props.modal}
                              mode="multiple"
                            />
                          </Grid>
                          <Grid item lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                            <UserAutocompleteFormItem
                              name="modifyUserGroup"
                              disabled={props.disabled}
                              label={i18n('entities.modeloTarea.fields.modifyUserGroup')}
                              required={false}
                              showCreate={!props.modal}
                              mode="multiple"
                            />
                          </Grid>
                        </React.Fragment>
                      )}

                    {watchTipoVisualizacion !== 'Procedimiento' && (
                      <React.Fragment>
                        <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                          <CategoriaAutocompleteFormItem
                            name="categoria"
                            disabled={props.disabled}
                            label={i18n('entities.modeloTarea.fields.categoria')}
                            required={true}
                            showCreate={!props.modal}
                          />
                        </Grid>
                        <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                          <HabilidadAutocompleteFormItem
                            name="habilidades"
                            disabled={props.disabled}
                            label={i18n('entities.modeloTarea.fields.habilidades')}
                            required={false}
                            showCreate={!props.modal}
                            mode="multiple"
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                      {!simpleView && (
                        <ObjetivoAutocompleteFormItem
                          name="objetivo"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.fields.objetivo')}
                          required={false}
                          showCreate={!props.modal}
                        />
                      )}
                    </Grid>
                    {!simpleView && (
                      <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                        <FilesFormItem
                          name="documentosAdjuntos"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.fields.documentosAdjuntos')}
                          required={false}
                          storage={Storage.values.modeloTareaDocumentosAdjuntos}
                          max={undefined}
                          formats={undefined}
                        />
                      </Grid>
                    )}
                    {!simpleView && (
                      <Grid item style={{marginBottom: 10}} lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                        <ImagesFormItem
                          name="fotosAdjuntas"
                          disabled={props.disabled}
                          label={i18n('entities.modeloTarea.fields.fotosAdjuntas')}
                          required={false}
                          storage={Storage.values.modeloTareaFotosAdjuntas}
                          max={undefined}
                        />
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
              )}

              <TabPanel value={tabValue} index={1} simpleView={simpleView}>
                <Grid item lg={props.drawer ? 12 : 12} md={8} sm={12} xs={12}>
                  <RecursoTableDetails rows={watchRecursosNecesarios} />

                  <RecursoAutocompleteFormItem
                    name="recursosNecesarios"
                    entity="recurso"
                    disabled={props.disabled}
                    label={i18n('entities.modeloTarea.fields.recursosNecesarios')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              </TabPanel>
            </Box>
          </Box>

          {!simpleView && !props.disabled && (
            <FormButtons
              style={{
                flexDirection: modal ? 'row-reverse' : undefined,
              }}>
              <Button
                variant="contained"
                color="primary"
                disabled={saveLoading}
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<SaveIcon />}
                size="small">
                {i18n('common.save')}
              </Button>

              <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
                {i18n('common.reset')}
              </Button>

              {props.onCancel ? (
                <Button
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<CloseIcon />}
                  size="small">
                  {i18n('common.cancel')}
                </Button>
              ) : null}
            </FormButtons>
          )}
          {simpleView && (
            <FormButtons
              style={{
                flexDirection: modal ? 'row-reverse' : undefined,
                marginTop: '-10px',
                marginBottom: '50px',
              }}>
              <Button
                variant="contained"
                style={props.disabled ? {backgroundColor: 'green', color: 'white'} : undefined}
                disabled={props.disabled || saveLoading}
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<CheckIcon />}
                size="small">
                {props.disabled ? i18n('common.ready') : i18n('common.saveTaskData')}
              </Button>
              {props.disabled && (
                <Button
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<EditIcon />}
                  size="small">
                  {'Editar'}
                </Button>
              )}
            </FormButtons>
          )}
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default ModeloTareaForm;
