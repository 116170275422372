import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import FormErrors from 'src/view/shared/form/formErrors';

import {FormControl, FormControlLabel, FormHelperText, Switch} from '@mui/material';
import {useFormContext, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import {DisabledText} from './shared/formItemWrappers';

function SwitchFormItem(props) {
  const {label, name, hint, required, externalErrorMessage, disabled, size, defaultValue, labelPlacement = 'start'} = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    control,
  } = useFormContext();

  useEffect(() => {
    register(name);
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const formHelperText = errorMessage || hint;

  const watchName: any = useWatch({name, control, defaultValue});
  if (disabled) {
    return <DisabledText label={label} value={watchName ? i18n('common.yes') : i18n('common.no')} />;
  }

  return (
    <FormControl required={required} error={Boolean(errorMessage)} component="fieldset" size="small">
      <FormControlLabel
        style={{
          marginLeft: 0,
        }}
        control={
          <Switch
            disabled={disabled}
            id={name}
            name={name}
            checked={watchName || false}
            onChange={(e) => {
              setValue(name, e.target.checked, {
                shouldValidate: true,
              });
              props.onChange && props.onChange(e.target.checked);
            }}
            onBlur={(event) => {
              props.onBlur && props.onBlur(event);
            }}
            color="primary"
            size={size || 'small'}></Switch>
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {formHelperText && <FormHelperText style={{marginTop: 0}}>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}

SwitchFormItem.defaultProps = {};

SwitchFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  onChange: PropTypes.any,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  defaultValue: PropTypes.any,
  labelPlacement: PropTypes.string,
};

export default SwitchFormItem;
