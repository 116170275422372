import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/auth/authSelectors';
import layoutActions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import I18nSelect from 'src/view/layout/I18nSelect';
import UserMenu from 'src/view/layout/UserMenu';
import authSelectors from '../../modules/auth/authSelectors';
import HeaderNotifications from './notificacions';

import {alpha, AppBar, IconButton, Toolbar} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';

import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {styled} from '@mui/material/styles';
import {getHistory} from 'src/modules/store';

import {makeStyles} from '@mui/styles';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

const useStyles = makeStyles((theme: any) => ({
  appBar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    fontSize: '1.5em',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    textDecoration: 'none',
  },
  grow: {
    flex: '1 1 auto',
  },
}));

const IconButtonPrimary = styled(IconButton)(
  ({theme}: any) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`,
);

function Header(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const logoUrl = useSelector(selectors.selectLogoUrl);

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);

  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);

  const doToggleMenu = () => {
    localStorage.setItem('menuVisible', (!menuVisible).toString());
    dispatch(layoutActions.doToggleMenu());
  };

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const permissionChecker = new PermissionChecker(currentTenant, currentUser);

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
        <IconButton edge="start" onClick={doToggleMenu} color="inherit">
          <MenuIcon />
        </IconButton>

        <Link className={classes.logo} to="/">
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={i18n('app.title')}
              style={{verticalAlign: 'middle', maxHeight: 64, width: 'auto', maxWidth: 128, height: 'auto'}}
            />
          ) : (
            <>{currentTenant?.name}</>
          )}
        </Link>

        <div className={classes.grow} />
        <img
          src={'/images/assets/digital/Transparente%2002.png'}
          alt={i18n('app.title')}
          style={{
            verticalAlign: 'middle',
            maxHeight: 35,
            width: 'auto',
            maxWidth: 258,
            height: 'auto',
            display: window.innerWidth < 490 ? 'none' : 'block',
          }}
        />
        <div className={classes.grow} />

        {/* <ThemeSettings /> */}
        <I18nSelect />

        {(permissionChecker.match(permissions.activoRead) ||
          permissionChecker.match(permissions.ubicacionRead) ||
          permissionChecker.match(permissions.categoriaRead) ||
          permissionChecker.match(permissions.userRead) ||
          permissionChecker.match(permissions.auditLogRead) ||
          permissionChecker.match(permissions.habilidadRead)) && (
          <PopupState variant="popover" popupId="id-popup-configurations">
            {(popupState) => (
              <React.Fragment>
                <IconButtonPrimary color="primary" {...bindTrigger(popupState)}>
                  <SettingsIcon />
                </IconButtonPrimary>
                <Menu {...bindMenu(popupState)}>
                  {permissionChecker.match(permissions.activoRead) && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        getHistory().push('/activo');
                      }}>
                      {i18n('entities.activo.menu')}
                    </MenuItem>
                  )}

                  {permissionChecker.match(permissions.ubicacionRead) && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        getHistory().push('/ubicacion');
                      }}>
                      {i18n('entities.ubicacion.menu')}
                    </MenuItem>
                  )}

                  {permissionChecker.match(permissions.categoriaRead) && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        getHistory().push('/categoria');
                      }}>
                      {i18n('entities.categoria.menu')}
                    </MenuItem>
                  )}

                  {permissionChecker.match(permissions.habilidadRead) && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        getHistory().push('/habilidad');
                      }}>
                      {i18n('entities.habilidad.menu')}
                    </MenuItem>
                  )}

                  {permissionChecker.match(permissions.userRead) && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        getHistory().push('/user');
                      }}>
                      {i18n('user.menu')}
                    </MenuItem>
                  )}

                  {permissionChecker.match(permissions.auditLogRead) && (
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        getHistory().push('/audit-logs');
                      }}>
                      {i18n('auditLog.menu')}
                    </MenuItem>
                  )}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        )}

        <HeaderNotifications />

        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
