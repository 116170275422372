import React from 'react';

import {Sms} from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import moment from 'moment-timezone';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Label from 'src/components/Label';
import {i18n} from 'src/i18n';
import tareaPdf from 'src/modules/tarea/tareaPdf';
import ChipActivoView from 'src/view/activo/view/ChipActivoView';
import ComunicacionInternaFormPage from 'src/view/comunicacionInterna/form/ComunicacionInternaFormPage';
import ComunicacionInternaListItem from 'src/view/comunicacionInterna/list/ComunicacionInternaListItem';
import ClasificacionTagView from 'src/view/shared/view/ClasificacionTagView';
import DrawerButton from 'src/view/shared/view/DrawerButton';

const CardWrapper = styled(Card)(
  ({theme}) => `
  position: relative;
  overflow: visible;
  //border: 2px solid ${theme.palette.divider};
  box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.07),
              0px 1px 1px 1px rgba(0,0,0,0.09),
              0px 1px 3px 1px rgba(0,0,0,0.12);
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
  &.Mui-selected::after {
    box-shadow: 0 0 0 3px ${theme['colors'].primary.main};
  }
  `,
);

const getTaskStatusLabel = (taskStatus) => {
  const map = {
    Abierta: {
      text: i18n(`entities.tarea.enumerators.estado.${taskStatus}`),
      color: 'info',
    },
    PendienteRevision: {
      text: i18n(`entities.tarea.enumerators.estado.${taskStatus}`),
      color: 'warning',
    },
    EnRevision: {
      text: i18n(`entities.tarea.enumerators.estado.${taskStatus}`),
      color: 'warning',
    },
    Cerrada: {
      text: i18n(`entities.tarea.enumerators.estado.${taskStatus}`),
      color: 'success',
    },
    Rechazada: {
      text: i18n(`entities.tarea.enumerators.estado.${taskStatus}`),
      color: 'error',
    },
    FueraPlazo: {
      text: i18n(`entities.tarea.enumerators.estado.${taskStatus}`),
      color: 'error',
    },
  };

  const {text, color} = map[taskStatus];

  return <Label color={color}>{text}</Label>;
};

function TaskCard(props) {
  let {value: task, viewType} = props;
  //console.log('%c⧭', 'color: #99adcc', {props});
  const {t} = useTranslation();

  if (!task) {
    return <React.Fragment />;
  }

  if (!viewType) {
    viewType = 'grid';
  }
  const [loadingPdf, setLoadingPdf] = useState(false);
  const doExportPdf = async (task) => {
    setLoadingPdf(true);
    try {
      await tareaPdf.exportDataAsPdf(task);
    } catch (error) {
      console.log({error: error?.message || error});
    }
    setLoadingPdf(false);
  };

  return (
    <>
      {viewType === 'table' && (
        <TableRow
          className={'highlightOnHover'} //props.rowIsActive === task.id ? 'highlightActive' :
          //onClick={() => props.setRowActive(props.rowIsActive === task.id ? null : task.id)}
          key={task.id}
          style={{cursor: 'pointer'}}>
          <TableCell style={{zIndex: 100}} sx={{pr: 0}}>
            {!task.comunicacionInterna ? (
              !props.hideComunicacionInterna && (
                <DrawerButton
                  drawerId={'DrawerComunicacionInternaFormPage'}
                  type={'icon'}
                  width={window.innerWidth * 0.55}
                  tooltipTitle={i18n('common.new')}
                  buttonTitle={i18n('common.new')}
                  buttonIcon={<Sms />}
                  component={
                    <ComunicacionInternaFormPage
                      passProps={{
                        tipo: 'Tarea',
                        id: task.id,
                      }}
                      drawer={true}
                    />
                  }
                />
              )
            ) : (
              <div style={{marginTop: 5}}>
                <ComunicacionInternaListItem value={task.comunicacionInterna} />
              </div>
            )}
          </TableCell>
          <TableCell onClick={props.onClick}>
            {task.tipoMantenimiento && (
              <ClasificacionTagView value={task.tipoMantenimiento} fieldName="tipoMantenimiento" entity="tarea" />
            )}
            {!(task.tipoMantenimiento && task.planificacion) && (
              <ClasificacionTagView isColor value={'grey'} label={i18n('common.notPlanned')} />
            )}
          </TableCell>
          <TableCell onClick={props.onClick}>
            <Typography noWrap variant="h5">
              {task.titulo}
            </Typography>
          </TableCell>
          <TableCell onClick={props.onClick} sx={{pr: 0}}>
            <Grid container alignItems="center" justifyContent="flex-start">
              {task.activo && (
                <Grid style={{marginRight: 10}} item>
                  <ChipActivoView type={'avatar'} record={task.activo} />
                </Grid>
              )}
              <Grid item>
                <Typography noWrap variant="h5">
                  {task.tituloTarea || task.modeloTarea?.titulo}
                </Typography>
                {task.subtitulo && (
                  <Typography noWrap component="span">
                    {task.subtitulo}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell onClick={props.onClick} sx={{pr: 0}}>
            <Typography variant="h5">{task.planificacion?.nombre}</Typography>
          </TableCell>
          <TableCell onClick={props.onClick}>
            <Typography noWrap color="text.secondary">
              {t('Ejecución: ')}
              <b>
                {task.inicioEstimado
                  ? moment(task.inicioEstimado).format('DD/MM/YYYY [a las] HH:mm') +
                    ' - ' +
                    moment(task.finEstimado).format('DD/MM/YYYY [a las] HH:mm')
                  : moment(task.fechaProgramacion).format('DD/MM/YYYY')}
              </b>
            </Typography>
            {task.fechaLimite && (
              <Typography noWrap variant="subtitle1" color="text.primary">
                {t('Fecha de Vencimiento: ')}
                <b>{moment(task.fechaLimite).format('DD/MM/YYYY')}</b>
              </Typography>
            )}
          </TableCell>
          <TableCell onClick={props.onClick}>
            <Box display="flex" justifyContent="center">
              {!task.isParentTask && <ClasificacionTagView value={task.prioridad} entity="tarea" fieldName="prioridad" />}
              {task.isParentTask && <ClasificacionTagView isColor value={'grey'} label={i18n('common.rootTask')} />}
            </Box>
          </TableCell>
          <TableCell onClick={props.onClick}>
            <Box display="flex" justifyContent="flex-start">
              <Tooltip
                followCursor
                arrow
                placement="top"
                key={task.ejecutor?.id}
                title={i18n('entities.tarea.fields.ejecutor') + ': ' + (task.ejecutor?.fullName || task.ejecutor?.email)}>
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                  key={task.ejecutor?.id}
                  src={task.ejecutor?.avatars.length > 0 ? task.ejecutor?.avatars[0]?.downloadUrl : undefined}
                />
              </Tooltip>
            </Box>
          </TableCell>
          <TableCell align="center" sx={{pr: 0}}>
            <Box onClick={props.onClick} sx={{minWidth: 100}} display="flex" alignItems="center">
              {!props.hideProgressbar && (
                <>
                  <LinearProgress
                    sx={{
                      //zoom: 1.25,
                      flex: 1,
                      mr: 1,
                      height: '0.6em',
                    }}
                    value={task.progreso?.toFixed(0) || 0}
                    color="primary"
                    variant="determinate"
                  />
                  <Typography variant="subtitle1">{task.progreso?.toFixed(0) || 0}%</Typography>
                </>
              )}
            </Box>
          </TableCell>
          {!task.isParentTask && (
            <TableCell onClick={props.onClick}>
              <ClasificacionTagView value={task.estado} entity="tarea" fieldName="estado" />
              {/* <Typography noWrap>{task.estado && getTaskStatusLabel(task.estado)}</Typography> */}
            </TableCell>
          )}
        </TableRow>
      )}
      {viewType === 'grid' && (
        <>
          <Grid
            style={{cursor: 'pointer', marginBottom: 15}}
            item
            xs={!props.viewType ? 12 : 12}
            sm={!props.viewType ? 12 : 6}
            md={!props.viewType ? 12 : 4}
            key={task.id}>
            <CardWrapper raised={false} sx={{m: 0.1}}>
              <Box
                sx={{
                  position: 'relative',
                  zIndex: '2',
                }}>
                <Box pl={2} py={0.5} pr={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Box onClick={props.onClick}>
                    <Typography variant="h5" pt={1}>
                      <b>{task.titulo}</b>
                    </Typography>
                  </Box>

                  <Box onClick={props.onClick} display="flex" alignItems="flex-end" justifyContent="space-between">
                    <ClasificacionTagView value={task.tipoMantenimiento} fieldName="tipoMantenimiento" entity="tarea" />
                  </Box>
                  <Tooltip followCursor title={task.isParentTask ? 'Tarea Origen' : i18n('entities.tarea.fields.estado')}>
                    <Box mr={1} onClick={props.onClick} display="flex" alignItems="flex-end" justifyContent="space-between">
                      {/* {!task.isParentTask && task.estado && getTaskStatusLabel(task.estado)} */}
                      {!task.isParentTask && task.estado && (
                        <ClasificacionTagView value={task.estado} entity="tarea" fieldName="estado" />
                      )}
                      {task.isParentTask && <ClasificacionTagView isColor value={'grey'} label={'Tarea Origen'} />}
                    </Box>
                  </Tooltip>
                </Box>
                <Divider />
                {/* <CardMedia
                    sx={{ minHeight: 180 }}
                    image={task.screenshot}
                  /> */}
                <Divider />
                <Box p={2}>
                  <Grid container direction="row" alignItems="flex-start" width="100%">
                    <Grid item>
                      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                        <Typography onClick={props.onClick} variant="h4">
                          {task.tituloTarea || task.modeloTarea?.titulo}
                        </Typography>
                        {!task.isParentTask && !props.hideComunicacionInterna && !task.comunicacionInterna ? (
                          <DrawerButton
                            drawerId={'DrawerComunicacionInternaFormPage'}
                            type={'icon'}
                            width={window.innerWidth * 0.55}
                            tooltipTitle={i18n('common.new')}
                            buttonTitle={i18n('common.new')}
                            buttonIcon={<Sms />}
                            component={
                              <ComunicacionInternaFormPage
                                passProps={{
                                  tipo: 'Tarea',
                                  id: task.id,
                                }}
                                drawer={true}
                              />
                            }
                          />
                        ) : (
                          !props.hideComunicacionInterna && <ComunicacionInternaListItem value={task.comunicacionInterna} />
                        )}
                      </Box>
                    </Grid>
                    <Tooltip followCursor placement="top" title={i18n('entities.tarea.fields.prioridad')}>
                      <Grid item ml="auto">
                        {!task.isParentTask && (
                          <ClasificacionTagView value={task.prioridad} entity="tarea" fieldName="prioridad" />
                        )}
                      </Grid>
                    </Tooltip>
                  </Grid>

                  {task.subtitulo && <Typography component="span">{task.subtitulo}</Typography>}

                  <Typography onClick={props.onClick} noWrap variant="subtitle2">
                    {task.planificacion?.nombre}
                  </Typography>
                </Box>
                <Box onClick={props.onClick} px={2} display="flex" alignItems="flex-end" justifyContent="space-between">
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box>
                        <Typography variant="subtitle1">{t('Ejecución')}: </Typography>
                        <Typography variant="h5">
                          {task.inicioEstimado
                            ? moment(task.inicioEstimado).format('DD/MM/YYYY [a las] HH:mm') +
                              ' - ' +
                              moment(task.finEstimado).format('DD/MM/YYYY [a las] HH:mm')
                            : moment(task.fechaProgramacion).format('DD/MM/YYYY')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {task.fechaLimite && (
                        <Box>
                          <Typography variant="subtitle1">{t('Fecha de Vencimiento')}: </Typography>
                          <Typography variant="h5">{moment(task.fechaLimite).format('DD/MM/YYYY')}</Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>

                <Box px={2} pb={2} display="flex" alignItems="center" onClick={props.onClick}>
                  {!task.isParentTask && !props.hideProgressbar && (
                    <>
                      <LinearProgress sx={{flex: 1, mr: 1}} value={task.progreso || 0} color="primary" variant="determinate" />
                      <Typography variant="subtitle1">{task.progreso?.toFixed(0) || 0}%</Typography>
                    </>
                  )}
                </Box>
                {!task.isParentTask && (
                  <>
                    <Divider />
                    <Divider />
                  </>
                )}
                {!task.isParentTask && (
                  <Box onClick={props.onClick} p={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" justifyContent="flex-start" width="100%" alignItems="center">
                      <Tooltip
                        followCursor
                        arrow
                        placement="top"
                        key={task.ejecutor?.id}
                        title={i18n('entities.tarea.fields.ejecutor')}>
                        <Box display="flex" flexDirection="row" width="100%" alignItems="center">
                          <Avatar
                            sx={{
                              width: 30,
                              height: 30,
                              marginRight: 1,
                            }}
                            key={task.ejecutor?.id}
                            src={task.ejecutor?.avatars?.length > 0 ? task.ejecutor?.avatars[0].downloadUrl : undefined}
                          />
                          <Typography variant="subtitle2" fontSize="1.04em">
                            {task.ejecutor?.fullName || task.ejecutor?.email}
                          </Typography>
                        </Box>
                      </Tooltip>

                      {!props.hideExportButton && ['Cerrada', 'EnRevision'].includes(task.estado) && (
                        <Grid //, 'PendienteRevision'
                          container
                          alignItems={'flex-end'}
                          justifyContent={'flex-end'}>
                          {loadingPdf && <CircularProgress size={20} />}
                          {!loadingPdf && (
                            <Button
                              type="button"
                              disabled={false}
                              onClick={($event) => {
                                $event.stopPropagation();
                                doExportPdf(task);
                              }}
                              startIcon={<DescriptionIcon />}
                              size="small">
                              {i18n('common.exportPdf')}
                            </Button>
                          )}
                        </Grid>
                      )}

                      {task.activo && (
                        <Grid container alignItems={'flex-end'} justifyContent={'flex-end'}>
                          <ChipActivoView type={'chip'} record={task.activo} />
                        </Grid>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </CardWrapper>
          </Grid>
        </>
      )}
    </>
  );
}

export default TaskCard;
