import {yupResolver} from '@hookform/resolvers/yup';

import {SendOutlined} from '@mui/icons-material';
import {Button, Grid} from '@mui/material';
import {uniqBy} from 'lodash';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import selectors from 'src/modules/tarea/form/tareaFormSelectors';
import {customButtonStyle} from 'src/theme/schemes/CustomTheme';
import ActivoAutocompleteFormItem from 'src/view/activo/autocomplete/ActivoAutocompleteFormItem';
import ModeloTareaAutocompleteFormItem from 'src/view/modeloTarea/autocomplete/ModeloTareaAutocompleteFormItem';
import PlanificacionAutocompleteFormItem from 'src/view/planificacion/autocomplete/PlanificacionAutocompleteFormItem';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputMultiSelectorFormItem from 'src/view/shared/form/items/InputMultiSelectorFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  web: yup.boolean(),
  createNewTask: yup.boolean(),
  newTaskTitle: yup.string().label(i18n('entities.modeloTarea.fields.titulo')),
  taskSubtitle: yup.string().label(i18n('entities.tarea.fields.subtitulo')),
  task: yup.mixed().label(i18n('entities.tarea.fields.task')),
  priority: yup.string().label(i18n('entities.tarea.fields.prioridad')),
  executors: yup.array().label(i18n('entities.planificadorTarea.fields.ejecutores')).required(),
  planning: yup.mixed().label(i18n('entities.tarea.fields.planificacion')),
  parentTask: yup.mixed().label(i18n('entities.tarea.fields.parentTask')),
  incidentResponse: yup.mixed().label(i18n('entities.tarea.fields.incidentResponse')),
  validators: yup.array().label(i18n('entities.tarea.fields.validadores')),
  dateStart: yupFormSchemas.date(i18n('entities.tarea.fields.fechaProgramacion'), {required: true}),
  dateEnd: yupFormSchemas.date(i18n('entities.tarea.fields.finEstimado'), {required: false}),
  assets: yup.array(),
  //incidente: yup.mixed().label(i18n('entities.tarea.fields.incidente')).required(),
  tiempoLimite: yup.number(),
  tipoTiempoLimite: yup.string(),
  clasificacion: yup.string(),
});

function TareaSendForm(props) {
  const {onSend, parentTask, incidentResponse, filter} = props;

  const activo = filter?.activo;

  const saveLoading = useSelector(selectors.selectSaveLoading);
  const loading = saveLoading;

  const [initialValues] = useState(() => {
    return {
      web: true,
      createNewTask: false,
      newTaskTitle: '',
      taskSubtitle: '',
      priority: 'Baja',
      executors: [],
      planning: props.planning || null,
      parentTask: parentTask,
      incidentResponse: incidentResponse,
      validators: [],
      dateStart: moment().toISOString(),
      dateEnd: null,
      assets: props.assets || [activo] || [],
      task: null,
      incidente: props.incidente,
      //activo: props.activo,
      //planificacion: false,
      //validadores: null,
      //tarea: null,
      //aceptadores: null,
      //ejecutor: null,
      //fechaEjecucion: moment().toISOString(),
      tiempoLimite: 0,
      tipoTiempoLimite: 'days',
      clasificacion: props.clasificacion || 'operativa',
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });
  const {handleSubmit, control} = form;

  const createNewTask = useWatch({
    control: control,
    name: 'createNewTask',
    defaultValue: false,
  });

  const task = useWatch({
    control: control,
    name: 'task',
    defaultValue: null,
  });

  const newTaskTitle = useWatch({
    control: control,
    name: 'newTaskTitle',
    defaultValue: null,
  });

  const executors = useWatch({
    control: control,
    name: 'executors',
    defaultValue: [],
  });

  const showFields = (!createNewTask && task) || (createNewTask && newTaskTitle);

  const executorsSelected = showFields && executors && executors.length > 0;

  const taskError = !createNewTask && !task;
  const newTaskTitleError = createNewTask && !newTaskTitle;

  const disabledForm = loading || taskError || newTaskTitleError || !executorsSelected;

  const appFields = task?.record?.appFields;
  //console.log('%c⧭ appFields', 'color: #731d6d', appFields);
  const appFieldsEnabled = appFields?.enabled;
  const appFieldsPlanification = !appFieldsEnabled || appFields?.planification;
  const appFieldsAssets = !appFieldsEnabled || (executorsSelected && appFields?.assets);
  //const appFieldsCurrentUserAssign = !appFieldsEnabled || appFields?.currentUserAssign;
  //const appFieldsCurrentUserValidate = !appFieldsEnabled || appFields?.currentUserValidate;
  const appFieldsValidators = !appFieldsEnabled || (executorsSelected && appFields?.validators);
  const appFieldsInheritParentTaskValidators = appFields?.inheritParentTaskValidators;
  const appFieldsStartDate = !appFieldsEnabled || appFields?.startDate;
  const appFieldsEndDate = !appFieldsEnabled || appFields?.endDate;

  useEffect(() => {
    //console.log('%c⧭ parentTask', 'color: #997326', parentTask);
    if (appFieldsInheritParentTaskValidators && parentTask?.validadores?.length) {
      const selectedValidators = form.getValues('validators');
      // unique by id
      const newValidators = uniqBy([...selectedValidators, ...parentTask.validadores], 'id');
      console.log(
        '%c⧭ appFieldsInheritParentTaskValidators',
        'color: #e57373',
        appFieldsInheritParentTaskValidators,
        newValidators,
      );
      form.setValue('validators', newValidators);
    }
  }, [appFieldsInheritParentTaskValidators]);

  const dateStart = useWatch({
    control: control,
    name: 'dateStart',
    defaultValue: moment().toISOString(),
  });

  return (
    <>
      {/* {loading && <Spinner />} */}
      <FormProvider {...form}>
        <form>
          <div style={{minHeight: 'calc(100vh - 250px)'}}>
            <InputMultiSelectorFormItem
              options={[
                {
                  value: false,
                  label: 'Seleccionar Tarea',
                  color: '#3f51b5',
                },
                {
                  value: true,
                  label: 'Crear Nueva Tarea',
                  color: '#3f51b5',
                },
              ]}
              name={`createNewTask`}
            />
            {!createNewTask && (
              <ModeloTareaAutocompleteFormItem
                name="task"
                label={i18n('common.selectTask')}
                //showCreate={true}
                onChange={(value) => {
                  console.log('%c⧭ value', 'color: #00b300', value);
                  if (value) {
                    form.setValue('task', value);
                    form.setValue('newTaskTitle', '');
                    form.setValue('priority', value.record.prioridad);
                  }
                }}
              />
            )}
            {createNewTask && <InputFormItem name="newTaskTitle" label={i18n('common.createTask')} />}
            {showFields && (
              <>
                <InputFormItem name="taskSubtitle" label={i18n('entities.tarea.fields.subtitulo')} />
                <InputMultiSelectorFormItem
                  options={[
                    {
                      value: 'Baja',
                      label: i18n('entities.tarea.enumerators.prioridad.Baja'),
                      color: 'green',
                    },
                    {
                      value: 'Media',
                      label: i18n('entities.tarea.enumerators.prioridad.Media'),
                      color: 'orange',
                    },
                    {
                      value: 'Alta',
                      label: i18n('entities.tarea.enumerators.prioridad.Alta'),
                      color: 'red',
                    },
                  ]}
                  label={i18n('entities.tarea.fields.prioridad')}
                  name={`priority`}
                />
                {appFieldsPlanification && (
                  <PlanificacionAutocompleteFormItem
                    name="planning"
                    label={i18n('entities.tarea.fields.planificacion')}
                    required={false}
                  />
                )}
                <UserAutocompleteFormItem
                  name="executors"
                  label={i18n('entities.planificadorTarea.fields.ejecutores')}
                  required={true}
                  mode="multiple"
                />
                {/* <UserAutocompleteFormItem
                  name={`aceptadores`}
                  label={i18n('entities.planificadorTarea.fields.aceptadores')}
                  required={false}
                  mode="multiple"
                /> */}
                {appFieldsValidators && (
                  <UserAutocompleteFormItem
                    name={`validators`}
                    label={i18n('entities.tarea.fields.validadores')}
                    required={false}
                    mode="multiple"
                    filter={{canSign: true}}
                  />
                )}
                {appFieldsAssets && (
                  <ActivoAutocompleteFormItem
                    name="assets"
                    label={i18n('entities.planificacion.fields.activo')}
                    required={false}
                    mode="multiple"
                  />
                )}
                <Grid container direction="row" spacing={2}>
                  {appFieldsStartDate && (
                    <Grid item>
                      <DatePickerFormItem
                        minDate={moment().toISOString()}
                        defaultValue={moment().toISOString()}
                        name="dateStart"
                        label={i18n('entities.tarea.fields.fechaProgramacion')}
                        required={true}
                        onChange={(date) => {
                          // set dateEnd to dateStart if dateEnd is less than dateStart
                          const dateEnd = form.getValues('dateEnd');
                          if (dateEnd && moment(dateEnd).isBefore(date)) {
                            form.setValue('dateEnd', date);
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {appFieldsEndDate && (
                    <Grid item>
                      <DatePickerFormItem
                        minDate={dateStart}
                        defaultValue={moment().toISOString()}
                        name="dateEnd"
                        label={i18n('entities.tarea.fields.fechaLimite')}
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </div>
          <Grid container>
            <Grid style={{padding: 20}} item lg={props.drawer ? 12 : 3} xs={12}>
              <Button
                style={{
                  width: '100%',
                  ...customButtonStyle,
                }}
                variant="contained"
                color="primary"
                disabled={disabledForm}
                type="button"
                onClick={handleSubmit(onSend)}
                endIcon={<SendOutlined />}
                size="large">
                {'Enviar Tarea'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}

export default TareaSendForm;
