import {ArrowDownward, ArrowUpward, Delete, FileCopy} from '@mui/icons-material';
import {Box, Button, Grid, IconButton, Typography, styled} from '@mui/material';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import SubseccionesFormularioForm from 'src/view/modeloFormulario/form/SubseccionesFormularioForm';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';

// import { List, AutoSizer } from 'react-virtualized';
import {Tooltip} from '@mui/material';
import {i18n} from 'src/i18n';
import CustomAccordion from 'src/view/shared/CustomAccordion';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';

const OnHoverBox = styled(Box)`
  display: flex;
  flexdirection: row;
  gap: 8px;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`;
export default function ModeloPrincipalFormularioForm(props) {
  const {clasificacion, name, esRespuesta, editar, onSave, customButton} = props;
  //console.log('%c⧭ ModeloPrincipalFormularioForm', 'color: #00a3cc', {editar});

  const {control, getValues} = useFormContext();

  const {fields, remove, append, insert, move} = useFieldArray({
    control,
    name,
  });

  const [expanded, setExpanded] = React.useState<string | false>(false); //fields?.[0]?.id);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    //console.log('%c⧭ handleChange', 'color: #00b300', {panel, newExpanded});
    setExpanded(newExpanded ? panel : false);
  };

  const moveUp = (index) => {
    if (index === 0) {
      return;
    }
    move(index, index - 1);
  };

  const moveDown = (index) => {
    if (index === fields.length - 1) {
      return;
    }
    move(index, index + 1);
  };

  const [recordToDelete, setRecordToDelete] = useState(null);

  const doCloseDeleteConfirmModal = () => {
    setRecordToDelete(null);
  };

  const doDeleteRecord = (index) => {
    remove(index);
    doCloseDeleteConfirmModal();
  };

  const doOpenDeleteConfirmModal = (index) => {
    setRecordToDelete(index);
  };

  const [recordToCopy, setRecordToCopy] = useState(null);

  const doCloseCopyConfirmModal = () => {
    setRecordToCopy(null);
  };

  const doCopyJob = async (original) => {
    const seccion = {...original};
    seccion.categoria = seccion.categoria + ' Copia ' + moment().format('DD-MM-YYYY HH:mm:ss');
    delete seccion.key;
    delete seccion.id;
    append(seccion);
    doCloseCopyConfirmModal();
  };

  const doOpenCopyConfirmModal = (index) => {
    const seccion = getValues(`${name}.${index}`);
    setRecordToCopy(seccion);
  };

  const renderSectionSummary = (item, index) => {
    return (
      <div style={{display: 'inline-flex', width: '100%', cursor: 'default'}}>
        <div
          style={{
            width: '80%',
            //backgroundColor: 'rgb(255,255,255,.925)',
            borderRadius: '5px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '4px',
            marginBottom: 10,
          }}>
          <div onClick={($e) => $e.stopPropagation()}>
            <InputFormItem
              name={`${name}.${index}.categoria`}
              defaultValue={item.categoria}
              label={'Sección ' + (index + 1)}
              variant="outlined"
              fontSize="1.3rem"
              labelFontSize="1.1rem"
              shrinkLabel={false}
            />
          </div>
        </div>
        <OnHoverBox style={{marginLeft: 'auto', alignSelf: 'center'}} onClick={($e) => $e.stopPropagation()}>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            style={{marginLeft: 10}}
            followCursor
            placement={'top-start'}
            title={i18n('common.dynamicSection')}>
            <div>
              <CheckboxFormItem name={`${name}.${index}.isDynamic`} label={''} />
            </div>
          </Tooltip>
          <Tooltip followCursor placement={'top-start'} title={i18n('common.copy')}>
            <IconButton size="small" color="primary" onClick={(_) => doOpenCopyConfirmModal(index)}>
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.moveDown')}>
            <IconButton size="small" color="primary" onClick={() => moveDown(index)}>
              <ArrowDownward />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.moveUp')}>
            <IconButton size="small" color="primary" onClick={() => moveUp(index)}>
              <ArrowUpward />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.destroy')}>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                doOpenDeleteConfirmModal(index);
              }}>
              <Delete />
            </IconButton>
          </Tooltip>
        </OnHoverBox>
      </div>
    );
  };

  const renderSectionDetails = (item, index) => {
    return (
      <div>
        <SubseccionesFormularioForm name={name} editar={editar} clasificacion={clasificacion} categoriaIndex={index} />
      </div>
    );
  };

  if (!esRespuesta) {
    return (
      <React.Fragment>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid style={{marginBottom: 30}}>
            <Grid item xs={12} lg={12}>
              <div style={{width: '100%', textAlign: 'center', marginBottom: 26}}>
                {clasificacion === 'operativa' && (
                  <Typography variant="h3" gutterBottom>
                    Formulario de Operaciones
                  </Typography>
                )}
                {clasificacion === 'mantenimiento' && (
                  <Typography variant="h3" gutterBottom>
                    Formulario de Mantenimiento
                  </Typography>
                )}
                {clasificacion === 'incidente' && (
                  <Typography variant="h3" gutterBottom>
                    Formulario de Incidente
                  </Typography>
                )}
              </div>
              {/* <hr style={{marginBottom: 10}} /> */}
              {!fields?.length && (
                <Button
                  onClick={() => append({categoria: '', subseccions: [{categoria: '', preguntas: []}]})}
                  variant="outlined"
                  color="primary"
                  type="button">
                  + Añadir Sección
                </Button>
              )}
              {/* {fields?.length === 0 && (
                <div style={{marginLeft: 'auto', alignSelf: 'center'}}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      append({categoria: '', subseccions: [{categoria: '', preguntas: []}]});
                    }}
                    style={{backgroundColor: '#fff', border: '1px solid rgb(63, 81, 181,.6)'}}>
                    <Add />
                  </IconButton>
                </div>
              )} */}
              {fields.map((item: any, index) => (
                <CustomAccordion
                  key={item.id}
                  item={item}
                  index={index}
                  uniqueKey={item.id}
                  entityName={i18n('common.section')}
                  renderSummary={renderSectionSummary}
                  renderDetails={renderSectionDetails}
                  detailTitle={i18n('common.subsections')}
                  expanded={expanded}
                  handleChange={handleChange}
                  topButtonClick={(item, index) => {
                    insert(index, {
                      categoria: '',
                      isDynamic: false,
                      subseccions: [{categoria: '', preguntas: [], isDynamic: false}],
                    });
                  }}
                  bottomButtonClick={(item, index) => {
                    insert(index + 1, {
                      categoria: '',
                      isDynamic: false,
                      subseccions: [{categoria: '', preguntas: [], isDynamic: false}],
                    });
                  }}
                />
              ))}
              {recordToCopy && (
                <ConfirmModal
                  title={i18n('common.areYouSureCopySeccion')}
                  onConfirm={() => doCopyJob(recordToCopy)}
                  onClose={() => doCloseCopyConfirmModal()}
                  okText={i18n('common.yes')}
                  cancelText={i18n('common.no')}
                />
              )}
              {(recordToDelete || recordToDelete === 0) && (
                <ConfirmModal
                  title={i18n('common.areYouSureDelete')}
                  onConfirm={() => doDeleteRecord(recordToDelete)}
                  onClose={() => doCloseDeleteConfirmModal()}
                  okText={i18n('common.yes')}
                  cancelText={i18n('common.no')}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  return null;
}
