import {Box, Button, Grid} from '@mui/material';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import planificadorTareaEnumerators from 'src/modules/planificadorTarea/planificadorTareaEnumerators';
import ModeloTareaAutocompleteFormItem from 'src/view/modeloTarea/autocomplete/ModeloTareaAutocompleteFormItem';
import ModeloTareaListItem from 'src/view/modeloTarea/list/ModeloTareaListItem';
import PuestoAutocompleteFormItem from 'src/view/puesto/autocomplete/PuestoAutocompleteFormItem';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import RadioFormItem from 'src/view/shared/form/items/RadioFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import SwitchFormItem from 'src/view/shared/form/items/SwitchFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';

import {MoreVert, Redo} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {IconButton, Tooltip, TooltipProps, Typography, styled, tooltipClasses} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import modeloTareaEnumerators from 'src/modules/modeloTarea/modeloTareaEnumerators';
import DayOfWeekPicker from 'src/view/shared/form/items/DayOfWeekPicker';
import Message from 'src/view/shared/message';
import BasicItemMenu from './BasicItemMenu';

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />)(
  ({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }),
);

export default function PlanificadorMultiTareas(props) {
  const {name, disabled, fromPlanificacion} = props;
  const [confirm, setConfirm] = useState(false);
  const [expanded, setExpanded] = useState(-1);

  const {control, getValues, watch, register, setValue} = useFormContext();

  const {fields, remove, append, insert} = useFieldArray({
    control,
    name,
  });

  const asignarHoraInicio = useWatch({
    control,
    name: `asignarHoraInicio`,
  });
  const tiempoEstimadoDefault = useWatch({
    control,
    name: `tiempoEstimadoDefault`,
  });
  //console.log('%c⧭', 'color: #ffa640', {tiempoEstimadoDefault});

  useEffect(() => {
    fields.forEach((item: any, index) => {
      register(`${name}[${index}].tarea`);
      setValue(`${name}[${index}].tarea`, item.tarea);

      register(`${name}[${index}].prioridad`);
      setValue(`${name}[${index}].prioridad`, item.prioridad || item.tarea?.prioridad);

      register(`${name}[${index}].subtitulo`);
      setValue(`${name}[${index}].subtitulo`, item.subtitulo);

      register(`${name}[${index}].dirigidoA`);
      setValue(`${name}[${index}].dirigidoA`, item.dirigidoA);

      register(`${name}[${index}].ejecutores`);
      setValue(`${name}[${index}].ejecutores`, item.ejecutores);

      register(`${name}[${index}].puestos`);
      setValue(`${name}[${index}].puestos`, item.puestos);

      register(`${name}[${index}].sucursales`);
      setValue(`${name}[${index}].sucursales`, item.sucursales);

      register(`${name}[${index}].activarTiempoLimite`);
      setValue(`${name}[${index}].activarTiempoLimite`, true);

      register(`${name}[${index}].tipoTiempoLimite`);
      setValue(`${name}[${index}].tipoTiempoLimite`, item.tipoTiempoLimite);

      register(`${name}[${index}].tiempoLimite`);
      setValue(`${name}[${index}].tiempoLimite`, item.tiempoLimite);

      register(`${name}[${index}].unexpirable`);
      setValue(`${name}[${index}].unexpirable`, item.unexpirable);

      register(`${name}[${index}].tiempoLimiteDiasHabiles`);
      setValue(`${name}[${index}].tiempoLimiteDiasHabiles`, item.tiempoLimiteDiasHabiles);

      register(`${name}[${index}].horaInicio`);
      setValue(`${name}[${index}].horaInicio`, item.horaInicio);

      register(`${name}[${index}].tiempoEstimado`);
      setValue(`${name}[${index}].tiempoEstimado`, item.tiempoEstimado);
      register(`${name}[${index}].horaFin`);
      setValue(`${name}[${index}].horaFin`, item.horaFin);

      register(`${name}[${index}].programar`);
      setValue(`${name}[${index}].programar`, item.programar);

      register(`${name}[${index}].fechaEjecucion`);
      setValue(`${name}[${index}].fechaEjecucion`, item.fechaEjecucion);

      register(`${name}[${index}].inicioProgramacion`);
      setValue(`${name}[${index}].inicioProgramacion`, item.inicioProgramacion);

      register(`${name}[${index}].finProgramacion`);
      setValue(`${name}[${index}].finProgramacion`, item.finProgramacion);

      register(`${name}[${index}].tipoPeriodicidad`);
      setValue(`${name}[${index}].tipoPeriodicidad`, item.tipoPeriodicidad); // || 'weeks'); // no setear hasta que se seleccione "programar"

      register(`${name}[${index}].periodicidad`);
      setValue(`${name}[${index}].periodicidad`, item.periodicidad); // || 1); // no setear hasta que se seleccione "programar"

      register(`${name}[${index}].esVisada`);
      setValue(`${name}[${index}].esVisada`, item.esVisada);

      register(`${name}[${index}].aceptadores`);
      setValue(`${name}[${index}].aceptadores`, item.aceptadores);

      register(`${name}[${index}].validadores`);
      setValue(`${name}[${index}].validadores`, item.validadores);

      register(`${name}[${index}].asignarFunciones`);
      setValue(`${name}[${index}].asignarFunciones`, item.asignarFunciones);

      register(`${name}[${index}].asignadorFunciones`);
      setValue(`${name}[${index}].asignadorFunciones`, item.asignadorFunciones);
    });
  }, []);

  const [confirmarPegarIndex, setConfirmarPegarIndex] = useState<any>(null);
  const [confirmarQuitarIndex, setConfirmarQuitarIndex] = useState<any>(null);

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const calcTiempoEstimado = (horaInicio, horaFin) => {
    return moment(horaFin).diff(moment(horaInicio), 'minutes', true);
  };
  const calcHoraFin = (horaInicio, tiempoEstimado) => {
    if (!horaInicio || !isPositiveOrZero(tiempoEstimado)) return null;
    return moment(horaInicio).add(tiempoEstimado, 'minutes');
  };

  // la fecha (y hora) mínima de inicio es la del fin máximo de sus predecesores
  const calcMinDate = (item, defaultDate: any = null) => {
    const predecesores = item.tarea.predecessors;
    if (!predecesores?.length) return defaultDate;
    const maxFin = predecesores.reduce((max, predecessorId) => {
      const predecessorIndex = fields.findIndex((item: any) => item.tarea.id === predecessorId);
      const predecessorFin = getEndOfTask(predecessorIndex);
      if (!predecessorFin) return max;
      if (!max || predecessorFin.isAfter(max)) return predecessorFin;
      return max;
    }, defaultDate);
    return maxFin;
  };

  // calcular inicio de programacion considerando días de la semana seleccionados
  const calcInicioProgramacion = (index) => {
    const programar = getValues(`${name}[${index}].programar`);
    const inicioProgramacion = getValues(`${name}[${index}].inicioProgramacion`);
    if (!programar || !inicioProgramacion) return null;
    const tipoPeriodicidad = getValues(`${name}[${index}].tipoPeriodicidad`);
    const diasSemana = getValues(`${name}[${index}].daysOfWeek`);
    const inicioProgramacionResult = moment(inicioProgramacion);
    // considerar días de semana si la es programacion semanal
    if (tipoPeriodicidad === 'weeks' && diasSemana?.length) {
      // buscar dia de la semana mayor o igual al del inicio de la programacion
      const diaSemanaProgramacion = inicioProgramacionResult.isoWeekday(); // domingo = 7
      let dayToAdd = diasSemana.find((dia) => dia >= diaSemanaProgramacion);
      // si los días de semana seleccionados son menores al del inicio de la programacion se agrega una semana
      if (dayToAdd) {
        inicioProgramacionResult.startOf('week').add(dayToAdd - 1, 'days');
      } else {
        inicioProgramacionResult
          .add(1, 'weeks')
          .startOf('week')
          .add(diasSemana[0] - 1, 'days');
      }
    }
    setValue(`${name}[${index}].inicioProgramacion`, inicioProgramacionResult.toDate());
    calcFinProgramacion(index, inicioProgramacionResult);
    setHoraInicio(index, fields[index], combineDateAndTime(inicioProgramacionResult, getHoraInicio(index)));
    return inicioProgramacionResult;
  };

  const calcFinProgramacion = (index, inicioProgramacion) => {
    // si la fecha de fin de la programacion es menor a la fecha de inicio de la programacion se ajusta la fecha de fin
    const finProgramacion = getValues(`${name}[${index}].finProgramacion`);
    if (finProgramacion && moment(finProgramacion).isBefore(inicioProgramacion)) {
      setValue(`${name}[${index}].finProgramacion`, inicioProgramacion.toDate());
    }
  };

  const getHoraInicio = (index) => {
    const horaInicio = getValues(`${name}[${index}].horaInicio`);
    return horaInicio;
  };
  const getHoraFin = (index) => {
    const horaFin = getValues(`${name}[${index}].horaFin`);
    return horaFin;
  };

  const getInicioProgramacion = (index) => {
    const inicioProgramacion = getValues(`${name}[${index}].inicioProgramacion`);
    return inicioProgramacion;
  };

  const getFechaEjecucion = (index) => {
    const fechaEjecucion = getInicioProgramacion(index) || getValues(`${name}[${index}].fechaEjecucion`);
    return fechaEjecucion ? moment(fechaEjecucion).startOf('day') : null;
  };

  const getFechaLimite = (index) => {
    const fechaEjecucion = getFechaEjecucion(index);
    if (fechaEjecucion) {
      const tiempoLimite = getValues(`${name}[${index}].tiempoLimite`);
      const tipoTiempoLimite = getValues(`${name}[${index}].tipoTiempoLimite`);
      return fechaEjecucion.add(tiempoLimite, tipoTiempoLimite).endOf('day');
    }
    return null;
  };

  // de una cantidad de minutos obtener la cantidad de dias que transcurren
  const getDaysFromMinutes = (minutes) => {
    if (!minutes) return 0;
    return Math.floor(minutes / 1440);
  };

  // combinar una fecha con una hora de moment
  const combineDateAndTime = (date, time) => {
    if (!date || !time) return null;
    time = moment(time);
    return date.set({
      hour: time.hour(),
      minute: time.minute(),
      second: time.second(),
      millisecond: time.millisecond(),
    });
  };

  const getEndOfTask = (index) => {
    if (index === undefined || index === null) return null;
    let horaFin = getValues(`${name}[${index}].horaFin`);
    let tiempoEstimado = getValues(`${name}[${index}].tiempoEstimado`);
    let fechaInicio = getFechaEjecucion(index);
    if (fechaInicio && tiempoEstimado) {
      fechaInicio.add(getDaysFromMinutes(tiempoEstimado), 'days');
    }
    // console.log('%c⧭ getEndOfTask [fechaInicio]', 'color: #00736b', fechaInicio?.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('%c⧭ getEndOfTask [horaFin]', 'color: #00736b', moment(horaFin)?.format('YYYY-MM-DD HH:mm:ss'));
    return horaFin ? combineDateAndTime(fechaInicio, horaFin) : fechaInicio;
  };

  const getStartOfTask = (index) => {
    if (index === undefined || index === null) return null;
    let horaInicio = getHoraInicio(index);
    let fechaInicio = getFechaEjecucion(index);
    return horaInicio ? combineDateAndTime(fechaInicio, horaInicio) : fechaInicio;
  };

  const setFechaEjecucion = (index, item, newFechaEjecucion) => {
    setValue(`${name}[${index}].fechaEjecucion`, newFechaEjecucion.toDate());
    const inicioProgramacion = getValues(`${name}[${index}].inicioProgramacion`);
    if (getValues(`${name}[${index}].programar`) && inicioProgramacion) {
      setValue(`${name}[${index}].inicioProgramacion`, newFechaEjecucion.toDate());
      onChangeInicioProgramacion(index, newFechaEjecucion, item);
    }
  };

  const setHoraInicio = (index, item, newHoraInicio) => {
    const fechaMinimaValida = calcMinDate(item, newHoraInicio);
    console.log('%c⧭ setHoraInicio', 'color: #8c0038', index + ' : ' + fechaMinimaValida?.format('YYYY-MM-DD HH:mm:ss'));
    const horaInicio = fechaMinimaValida;
    setValue(`${name}[${index}].horaInicio`, horaInicio?.toDate());
    const tiempoEstimado = getValues(`${name}[${index}].tiempoEstimado`);
    console.log('%c⧭ tiempoEstimado', 'color: #8c0038', tiempoEstimado);
    const newHoraFin = calcHoraFin(horaInicio?.toDate(), tiempoEstimado);
    console.log('%c⧭', 'color: #607339', index + ' : ' + newHoraFin?.format('YYYY-MM-DD HH:mm:ss'));
    setHoraFin(index, item, newHoraFin); // hay que llamar este método igual para continuar la recursión aunque el valor sea null
    return true;
  };

  const isPositiveOrZero = (value: any) => {
    return value !== null && value !== undefined && value >= 0;
  };

  const setHoraFin = (index, item, newHoraFin) => {
    const horaFin = newHoraFin;
    console.log('%c⧭ setHoraFin', 'color: #514080', index + ': ' + horaFin?.format('YYYY-MM-DD HH:mm:ss'));
    setValue(`${name}[${index}].horaFin`, horaFin?.toDate());
    setStartOfSuccessors(index, item, horaFin);
  };

  // asignar inicio de los sucesores de la tarea
  const setStartOfSuccessors = (index, item, newHoraFin: any = null) => {
    const endOfTask = newHoraFin || getEndOfTask(index);
    //console.log('%c⧭ endOfTask', 'color: #cc0036', !!endOfTask);
    if (!endOfTask) return;
    const successors = item.tarea.successors || [];
    for (let s of successors) {
      //console.log('%c⧭', 'color: #d0bfff', index+' ?????');
      // obtener el indice de la tarea sucesora
      const sIndex = fields.findIndex((t: any) => t.tarea.id === s);
      if (sIndex === -1) continue;
      // validar si la tarea sucesora tiene un inicio asignado
      const startOfSuccessor = getStartOfTask(sIndex);
      console.log('%c⧭ startOfSuccessor', 'color: #994d75', index + ': ' + startOfSuccessor?.format('YYYY-MM-DD HH:mm:ss'));
      console.log('%c⧭ endOfTask', 'color: #7f2200', index + ': ' + endOfTask?.format('YYYY-MM-DD HH:mm:ss'));
      if (endOfTask.isAfter(startOfSuccessor)) {
        // actualizar el final de la tarea sucesora de forma recursiva
        setFechaEjecucion(sIndex, fields[sIndex], endOfTask);
        setHoraInicio(sIndex, fields[sIndex], endOfTask);
      }
    }
    return;
  };

  const onChangeFechaEjecucion = (index, value, item) => {
    const newHoraInicio = combineDateAndTime(moment(value), getHoraInicio(index));
    setHoraInicio(index, item, newHoraInicio);
  };

  const onChangeInicioProgramacion = (index, value, item) => {
    calcInicioProgramacion(index);
  };

  const onChangeDaysOfWeek = (index, value, item) => {
    calcInicioProgramacion(index);
  };

  const onChangeHoraInicio = (index, value, item) => {
    const newHoraInicio = combineDateAndTime(getFechaEjecucion(index), moment(value));
    setHoraInicio(index, item, newHoraInicio);
  };

  const onChangeTiempoEstimado = (index, value, item) => {
    const horaInicio = getHoraInicio(index);
    if (horaInicio) {
      const horaFin = calcHoraFin(horaInicio, value);
      setHoraFin(index, item, horaFin);
    }
  };

  const onChangeHoraFin = (index, value, item) => {
    // calcular fecha base considerando días de ejecución
    const tiempoEstimado = getTiempoEstimado(index);
    const fechaBase = getFechaEjecucion(index); // no es necesario calcular la fecha mínima porque se calcula antes de asignar la fecha de ejecución
    if (tiempoEstimado && fechaBase) {
      // obtener días desde los minutos y sumarlos a la fecha base de ejecución
      fechaBase.add(getDaysFromMinutes(tiempoEstimado), 'days');
    }
    const newHoraFin = combineDateAndTime(fechaBase, moment(value));
    const horaInicio = getHoraInicio(index);
    if (horaInicio) {
      const tiempoEstimado = calcTiempoEstimado(horaInicio, newHoraFin.toDate());
      setValue(`${name}[${index}].tiempoEstimado`, tiempoEstimado);
    }
    setHoraFin(index, item, newHoraFin);
  };

  //getTiempoEstimado
  const getTiempoEstimado = (index) => getValues(`${name}[${index}].tiempoEstimado`);

  const getFechaInicioProgramacionDefault = () => {
    const today = moment();
    const fechaEstimadaInicio = getValues(`planificacion.fechaEstimadaInicio`);
    if (!fechaEstimadaInicio) return today;
    const fechaInicioProgramacion = moment(fechaEstimadaInicio);
    if (fechaInicioProgramacion.isBefore(today)) {
      return today;
    }
    return fechaInicioProgramacion;
  };

  const getFechaFinProgramacionDefault = () => {
    const today = moment();
    const fechaEstimadaFin = getValues(`planificacion.fechaEstimadaFin`);
    if (!fechaEstimadaFin) return today;
    const fechaFinProgramacion = moment(fechaEstimadaFin);
    if (fechaFinProgramacion.isBefore(today)) {
      return today;
    }
    return fechaFinProgramacion;
  };

  // formato moment segun tiempo estimado
  const finFormat = (index) => {
    return 'DD/MM/YYYY [a las] HH:mm';
    /** probando otro formato
    const tiempoEstimado = getTiempoEstimado(index);
    if (tiempoEstimado && getDaysFromMinutes(tiempoEstimado) > 0) {
      return 'DD/MM/YYYY [a las] HH:mm';
    }
    return '[a las] HH:mm';*/
  };

  // IsolateReRender
  function RenderTitleDates(props) {
    const {index} = props;
    useWatch({name: `${name}[${index}].fechaEjecucion`});
    useWatch({name: `${name}[${index}].inicioProgramacion`});
    useWatch({name: `${name}[${index}].horaInicio`});
    useWatch({name: `${name}[${index}].horaFin`});
    return (
      <Grid xs item>
        <div style={{marginRight: 10}}>
          <span style={{marginTop: 4, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}>
            <span style={{marginLeft: 'auto'}}>
              <b>Inicio:</b>
            </span>
            <span style={{marginLeft: asignarHoraInicio ? 'auto' : 10}}>
              {getFechaEjecucion(index)?.format('DD/MM/YYYY') || '_'}
              {asignarHoraInicio && ` a las ${getStartOfTask(index)?.format('HH:mm') || '_'}`}
            </span>
          </span>
          {asignarHoraInicio && (
            <span style={{marginTop: 4, whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}>
              <span style={{marginLeft: 'auto', marginRight: '-15px'}}>
                <b>Fin:</b>
              </span>
              <span style={{marginLeft: 'auto'}}>{`${getEndOfTask(index)?.format(finFormat(index)) || '_'}`}</span>
            </span>
          )}
        </div>
      </Grid>
    );
  }

  // asignar hora inicio y fin de la tarea y sus sucesoras recursivamente
  const asignarHoraInicioFinTareasSucesoras = (index, item) => {
    const horaInicio = getHoraInicio(index);
    const tiempoEstimado = getTiempoEstimado(index);
    if (horaInicio && tiempoEstimado) {
      const horaFin = moment(horaInicio).add(tiempoEstimado, 'minutes');
      setHoraFin(index, item, horaFin);
      asignarInicioSucesoras(index, item, horaFin);
    }
  };

  // asignar inicio de las sucesoras sin importar si tienen hora de inicio
  const asignarInicioSucesoras = (index, item, newHoraInicio) => {
    const successors = item.tarea?.successors || [];
    for (let s of successors) {
      const sIndex = fields.findIndex((t: any) => t.tarea.id === s);
      if (sIndex === -1) continue;
      const sItem = fields[sIndex];
      setHoraInicio(sIndex, sItem, newHoraInicio);
      asignarHoraInicioFinTareasSucesoras(sIndex, sItem); // recursividad
    }
  };

  // recalcular horas de inicio y fin de las tareas sucesoras
  const recalcularHoraInicioFinTareasSucesoras = () => {
    fields.forEach((item: any, index) => {
      const predecessors = item.tarea.predecessors || [];
      if (predecessors.length === 0) {
        asignarHoraInicioFinTareasSucesoras(index, item);
      }
    });
  };

  /**
   * Copiar configuracion de 1 tarea programada (para poder pegarla en otras tareas)
   */
  const [configuracionCopiada, setConfiguracionCopiada] = useState<any>(null);
  const copiarConfiguracionTarea = (index) => {
    const item = getValues(`${name}[${index}]`);
    const copiada = {copyIndex: index, ...item};
    //console.log('%c⧭', 'color: #e57373', {copiada});
    setConfiguracionCopiada(copiada);
    Message.success('Configuración copiada');
  };

  /**
   * Pegar configuración tarea
   */
  const pegarConfiguracionTarea = (index) => {
    if (!configuracionCopiada) return;
    const copyIndex = configuracionCopiada.copyIndex;
    if (copyIndex === index) return;
    const copyItem = configuracionCopiada;
    const copyItemKeys = Object.keys(copyItem);
    for (let key of copyItemKeys) {
      if (
        [
          'copyIndex',
          'tarea',
          'id',
          '_id',
          // 'horaInicio',
          // 'horaFin',
          // 'tiempoEstimado',
          // 'inicioProgramacion',
          // 'fechaEjecucion',
          // 'fechaFinProgramacion',
          // 'fechaInicioProgramacion',
          // 'fechaFin',
          // 'fechaInicio',
          // 'programar',
        ].includes(key)
      )
        continue;
      setValue(`${name}[${index}].${key}`, copyItem[key]);
    }
    const pegada = getValues(`${name}[${index}]`);
    //console.log('%c⧭', 'color: #e57373', {pegada});
    Message.success('Configuración pegada');
  };

  // Tareas no expirables, no vencen, y se pueden asignar al pasado también
  const checkExpirable = (index) => {
    const tarea = getValues(`${name}[${index}]`);
    const isUnExpirable = !!tarea?.unexpirable;
    //console.log('%c⧭', 'color: #f200e2', {isUnExpirable});
    return !isUnExpirable;
  };

  return (
    <Box>
      <Box>
        <Box>
          <Box>
            <SwitchFormItem
              defaultValue={asignarHoraInicio}
              onChange={(value) => {
                console.log('%c⧭ onChange asignarHoraInicio', 'color: #00ff88', value);
                setValue('asignarHoraInicio', value);
                // set default tiempoEstimado if value is set
                if (value) {
                  fields.forEach((item: any, index) => {
                    const horaInicio = combineDateAndTime(getFechaEjecucion(index), new Date());
                    setValue(`${name}[${index}].horaInicio`, horaInicio.toDate());
                    setValue(`${name}[${index}].tiempoEstimado`, tiempoEstimadoDefault);
                    setValue(`${name}[${index}].horaFin`, horaInicio.add(tiempoEstimadoDefault, 'minutes').toDate());
                  });
                }
                // for every field with no predecessors, set the start date
                fields.forEach((item: any, index) => {
                  if (value) {
                    const predecessors = item.tarea.predecessors || [];
                    if (predecessors.length === 0) {
                      const horaInicio = combineDateAndTime(getFechaEjecucion(index), new Date());
                      console.log('%c⧭ horaInicio', 'color: #00258c', horaInicio.format('DD/MM/YYYY HH:mm'));
                      setHoraInicio(index, item, horaInicio); // esto activa el calculo recursivo de la fecha de inicio y fin
                    }
                  } else {
                    setValue(`${name}[${index}].horaInicio`, null);
                    setValue(`${name}[${index}].horaFin`, null);
                    setValue(`${name}[${index}].tiempoEstimado`, null);
                  }
                });
              }}
              name="asignarHoraInicio"
              label={i18n('entities.planificadorTarea.fields.asignarHoraInicio')}
            />
          </Box>
          {asignarHoraInicio && (
            <Grid container direction="row" alignItems={'center'} spacing={2}>
              <Grid item>
                <InputNumberFormItem
                  style={{minWidth: 250}}
                  defaultValue={tiempoEstimadoDefault}
                  name="tiempoEstimadoDefault"
                  label={i18n('entities.planificadorTarea.fields.tiempoEstimadoDefault')}
                  disableFormat={true}
                />
              </Grid>
              {/* small button */}
              <Grid item>
                <Button
                  color="primary"
                  style={{height: 28}}
                  variant="contained"
                  onClick={() => {
                    fields.forEach((item: any, index) => {
                      setValue(`${name}[${index}].tiempoEstimado`, tiempoEstimadoDefault);
                    });
                    recalcularHoraInicioFinTareasSucesoras();
                  }}>
                  {i18n('common.apply')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      {fields.map((item: any, index) => (
        <Accordion
          sx={{boxShadow: '0px 6px 6px rgb(159 162 191 / 18%), 0px 1px 1px rgb(159 162 191 / 32%)'}}
          expanded={expanded === index}
          onChange={handleChangeAccordion(index)}
          disableGutters={true}>
          <AccordionSummary
            sx={{height: 80}}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Grid
              container
              alignItems="center"
              style={{
                justifyContent: 'flex-start',
              }}
              direction="row"
              spacing={0}>
              <Grid xs item style={{flexGrow: 0}}>
                <h2 style={{whiteSpace: 'nowrap'}}>{item.order || index + 1} -</h2>
              </Grid>
              <Grid xs={8} item>
                <h2
                  style={{
                    marginLeft: 15,
                    marginTop: 17,
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  {!fromPlanificacion && <ModeloTareaListItem value={watch(`${name}[${index}].tarea`)} />}
                  {fromPlanificacion && (
                    <HtmlTooltip
                      disableHoverListener={false}
                      enterDelay={800}
                      leaveDelay={100}
                      enterNextDelay={300}
                      followCursor
                      title={
                        <React.Fragment>
                          {/*  de {item.tarea?.titulo} */}
                          <Typography color="inherit">Predecesoras:</Typography>
                          <ul style={{textAlign: 'left'}}>
                            {item.tarea?.predecessors?.map((predecessor: any) => {
                              // obtener el predecessor
                              const predecessorItem: any = fields.find((item: any) => item.tarea?.id === predecessor);
                              return (
                                <li style={{marginLeft: -20, marginTop: -2}}>
                                  <Typography color="inherit" key={predecessor}>
                                    {predecessorItem?.order} {predecessorItem?.tarea?.titulo}
                                  </Typography>
                                </li>
                              );
                            })}
                          </ul>
                        </React.Fragment>
                      }>
                      <span>{item.tarea?.titulo}</span>
                    </HtmlTooltip>
                  )}
                </h2>
              </Grid>
              {<RenderTitleDates index={index} />}
              {/* menu of options */}
              {!props.disabled && (
                <Grid xs item style={{flexGrow: 0}}>
                  <BasicItemMenu
                    openButton={(buttonProps) => (
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                          event.stopPropagation();
                          buttonProps.onClick();
                        }}
                        style={{marginTop: 10, marginRight: 10}}>
                        <MoreVert />
                      </IconButton>
                    )}
                    options={[
                      {
                        label: i18n('entities.planificadorTarea.taskMenuOptions.copiarConfiguracion'),
                        onClick: () => copiarConfiguracionTarea(index),
                        //hide: configuracionCopiada?.copyIndex === index,
                      },
                      {
                        label: i18n('entities.planificadorTarea.taskMenuOptions.pegarConfiguracion'),
                        onClick: () => setConfirmarPegarIndex(index),
                        hide: !configuracionCopiada || configuracionCopiada?.copyIndex === index,
                      },
                      {
                        label: i18n('entities.planificadorTarea.taskMenuOptions.quitarTarea'),
                        onClick: () => setConfirmarQuitarIndex(index),
                      },
                    ]}
                  />
                </Grid>
              )}
              {confirmarPegarIndex === index && (
                <ConfirmModal
                  title={i18n('common.areYouSureYouWantToPasteTaskConfiguration')}
                  onConfirm={(event) => {
                    event.stopPropagation();
                    pegarConfiguracionTarea(index);
                    setConfirmarPegarIndex(null);
                  }}
                  onClose={(event) => {
                    event.stopPropagation();
                    setConfirmarPegarIndex(null);
                  }}
                  okText={i18n('common.yes')}
                  cancelText={i18n('common.no')}
                />
              )}
              {confirmarQuitarIndex === index && (
                <ConfirmModal
                  title={i18n('common.areYouSureYouWantToRemoveThisTaskFromTheSchedule')}
                  onConfirm={(event) => {
                    event.stopPropagation();
                    remove(confirmarQuitarIndex);
                    Message.success(i18n('common.taskRemovedFromSchedule'));
                    setConfiguracionCopiada(null);
                    setConfirmarQuitarIndex(null);
                  }}
                  onClose={(event) => {
                    event.stopPropagation();
                    setConfirmarQuitarIndex(null);
                  }}
                  okText={i18n('common.yes')}
                  cancelText={i18n('common.no')}
                />
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                pl: 5,
                mt: 0,
                width: '100%',
                border: disabled && '1px solid #c9c3c3',
                borderRadius: disabled ? '5px' : 0,
                minHeight: '40vh',
              }}
              key={item.id}>
              <Grid item lg={12} md={8} sm={12} xs={12}>
                {!fromPlanificacion && (
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <ModeloTareaAutocompleteFormItem
                      name={`${name}[${index}].tarea`}
                      disabled={disabled || fromPlanificacion}
                      entity="modelo-tarea"
                      filter={{
                        planificaciones: watch('planificacion')?.id,
                      }}
                      onChange={(value) => {
                        if (value?.record) {
                          console.log('%c⧭ ModeloTareaOnChange', 'color: #7f2200', {item});
                          const tarea = item.tarea;
                          setValue(`${name}[${index}].tarea`, {
                            ...value.record,
                            successors: tarea?.successors || [],
                            predecessors: tarea?.predecessors || [],
                            order: tarea?.order || index + 1,
                            type: tarea?.type || 0,
                          });
                        }
                      }}
                      label={i18n('entities.planificadorTarea.fields.tareas')}
                      required={true}
                      showCreate={true}
                    />
                  </Grid>
                )}

                <Grid item lg={8} md={8} sm={12} xs={12}>
                  {/* subtitulo de la tarea */}
                  <InputFormItem
                    name={`${name}[${index}].subtitulo`}
                    disabled={disabled}
                    label={i18n('entities.tarea.fields.subtitulo') + ' (opcional)'}
                    required={false}
                  />
                </Grid>

                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <SelectFormItem
                    name={`${name}[${index}].prioridad`}
                    disabled={props.disabled}
                    label={i18n('entities.modeloTarea.fields.prioridad')}
                    options={modeloTareaEnumerators.prioridad.map((item) => ({
                      value: item,
                      label: i18n(`entities.modeloTarea.enumerators.prioridad.${item}`),
                    }))}
                  />
                </Grid>

                {watch(`${name}[${index}].tarea`)?.id && (
                  <React.Fragment>
                    <Grid item style={{marginBottom: 10}} lg={8} md={8} sm={12} xs={12}>
                      <SwitchFormItem
                        name={`${name}[${index}].esVisada`}
                        disabled={disabled}
                        label={i18n('entities.planificadorTarea.fields.esVisada')}
                      />
                    </Grid>
                    {watch(`${name}[${index}].esVisada`) && (
                      <Grid item style={{marginBottom: 10}} lg={8} md={8} sm={12} xs={12}>
                        <UserAutocompleteFormItem
                          name={`${name}[${index}].aceptadores`}
                          disabled={disabled}
                          entity="user"
                          label={i18n('entities.planificadorTarea.fields.aceptadores')}
                          required={false}
                          showCreate={true}
                          mode="multiple"
                          filter={{canSign: true}}
                        />
                        <UserAutocompleteFormItem
                          entity="user"
                          name={`${name}[${index}].validadores`}
                          disabled={disabled}
                          label={i18n('entities.planificadorTarea.fields.validadores')}
                          required={false}
                          showCreate={true}
                          mode="multiple"
                          filter={{canSign: true}}
                        />
                      </Grid>
                    )}
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <SelectFormItem
                        name={`${name}[${index}].dirigidoA`}
                        disabled={disabled}
                        label={i18n('entities.planificadorTarea.fields.dirigidoA')}
                        options={planificadorTareaEnumerators.dirigidoA.map((value) => ({
                          value,
                          label: i18n(`entities.planificadorTarea.enumerators.dirigidoA.${value}`),
                        }))}
                        required={false}
                      />
                    </Grid>
                    {watch(`${name}[${index}].dirigidoA`) === 'Ejecutor' && (
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <UserAutocompleteFormItem
                          entity="user"
                          name={`${name}[${index}].ejecutores`}
                          disabled={disabled}
                          label={i18n('entities.planificadorTarea.fields.ejecutores')}
                          required={false}
                          showCreate={true}
                          mode="multiple"
                        />
                      </Grid>
                    )}
                    {watch(`${name}[${index}].dirigidoA`) === 'Puesto' && (
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <PuestoAutocompleteFormItem
                          entity="puesto"
                          name={`${name}[${index}].puestos`}
                          disabled={disabled}
                          label={i18n('entities.planificadorTarea.fields.puestos')}
                          required={false}
                          showCreate={true}
                          mode="multiple"
                        />
                      </Grid>
                    )}

                    {watch(`${name}[${index}].dirigidoA`) &&
                      (watch(`${name}[${index}].ejecutores`)?.length > 0 || watch(`${name}[${index}].puestos`)?.length > 0) && (
                        <React.Fragment>
                          {/* <Grid
                            item
                            lg={12}
                            md={8}
                            sm={12}
                            xs={12}
                          >
                            <SwitchFormItem
                              name={`${name}[${index}].activarTiempoLimite`}
                              disabled={disabled}
                              label={i18n(
                                'entities.planificadorTarea.fields.activarTiempoLimite',
                              )}
                            />
                          </Grid> */}

                          <Grid item lg={12} md={8} sm={12} xs={12}>
                            <RadioFormItem
                              name={`${name}[${index}].tipoTiempoLimite`}
                              disabled={disabled}
                              label={i18n('entities.planificadorTarea.fields.tipoTiempoLimite')}
                              options={planificadorTareaEnumerators.tipoTiempoLimite.map((value) => ({
                                value,
                                label: i18n(`entities.planificadorTarea.enumerators.tipoTiempoLimite.${value}`),
                              }))}
                              required={true}
                            />
                          </Grid>

                          {watch(`${name}[${index}].tipoTiempoLimite`) ? (
                            <Grid container>
                              <Grid item sm={4} xs={12}>
                                <InputNumberFormItem
                                  name={`${name}[${index}].tiempoLimite`}
                                  disabled={disabled}
                                  onChange={(value) => {
                                    if (!isPositiveOrZero(value)) value = 0;
                                    //console.log('%c⧭ onChange tiempoLimite', 'color: #ff6600', value);
                                    setValue(`${name}[${index}].tiempoLimite`, value);
                                  }}
                                  label={i18n('entities.planificadorTarea.fields.tiempoLimite')}
                                  required={true}
                                  disableFormat={true}
                                />
                              </Grid>
                            </Grid>
                          ) : null}

                          <Grid item sm={12} xs={12} style={{paddingTop: 6, paddingBottom: 8}}>
                            <SwitchFormItem
                              name={`${name}[${index}].tiempoLimiteDiasHabiles`}
                              disabled={disabled}
                              label={i18n('entities.planificadorTarea.fields.tiempoLimiteDiasHabiles')}
                            />
                          </Grid>

                          <Tooltip
                            disableHoverListener={false}
                            enterDelay={800}
                            leaveDelay={100}
                            enterNextDelay={300}
                            followCursor
                            title={i18n('common.theyWontExpireWhenTimeLimitIsReached')}>
                            <Grid item sm={12} xs={12} style={{paddingBottom: 8}}>
                              <SwitchFormItem
                                name={`${name}[${index}].unexpirable`}
                                disabled={disabled}
                                label={i18n('entities.planificadorTarea.fields.unexpirable')}
                              />
                            </Grid>
                          </Tooltip>

                          <Grid item lg={12} md={8} sm={12} xs={12}>
                            <SwitchFormItem
                              name={`${name}[${index}].programar`}
                              onChange={(value) => {
                                if (value) {
                                  setValue(`${name}[${index}].inicioProgramacion`, getFechaInicioProgramacionDefault().toDate());
                                  setValue(`${name}[${index}].finProgramacion`, getFechaFinProgramacionDefault().toDate());
                                  setValue(`${name}[${index}].tipoPeriodicidad`, 'days');
                                  setValue(`${name}[${index}].periodicidad`, 1);
                                } else {
                                  setValue(`${name}[${index}].inicioProgramacion`, null);
                                  setValue(`${name}[${index}].finProgramacion`, null);
                                  setValue(`${name}[${index}].tipoPeriodicidad`, null);
                                  setValue(`${name}[${index}].periodicidad`, null);
                                }
                              }}
                              disabled={disabled}
                              label={i18n('entities.planificadorTarea.fields.programar')}
                            />
                          </Grid>

                          {!watch(`${name}[${index}].programar`) && (
                            <Grid item lg={12} md={8} sm={12} xs={12}>
                              <DatePickerFormItem
                                minDate={
                                  checkExpirable(index)
                                    ? calcMinDate(item, getFechaInicioProgramacionDefault()).toISOString()
                                    : undefined
                                }
                                maxDate={moment(watch('planificacion')?.fechaEstimadaFin).toISOString()}
                                disabled={disabled}
                                name={`${name}[${index}].fechaEjecucion`}
                                label={i18n('entities.planificadorTarea.fields.fechaEjecucion')}
                                required={false}
                                onChange={(value) => onChangeFechaEjecucion(index, value, item)}
                              />
                            </Grid>
                          )}

                          {watch(`${name}[${index}].programar`) ? (
                            <React.Fragment>
                              <Grid item lg={12} md={8} sm={12} xs={12}>
                                <DatePickerFormItem
                                  minDate={
                                    checkExpirable(index)
                                      ? calcMinDate(item, getFechaInicioProgramacionDefault()).toISOString()
                                      : undefined
                                  }
                                  maxDate={getFechaFinProgramacionDefault().toISOString()}
                                  //resetValueFormItemName={`${name}[${index}].finProgramacion`} // ahora se setea en el onChange
                                  disabled={disabled}
                                  name={`${name}[${index}].inicioProgramacion`}
                                  label={i18n('entities.planificadorTarea.fields.inicioProgramacion')}
                                  required={false}
                                  onChange={(value) => onChangeInicioProgramacion(index, value, item)}
                                />
                              </Grid>
                              <Grid item lg={12} md={8} sm={12} xs={12}>
                                <DatePickerFormItem
                                  minDate={
                                    getStartOfTask(index)?.toISOString() ||
                                    moment(watch(`${name}[${index}].inicioProgramacion`)).toISOString()
                                  }
                                  maxDate={getFechaFinProgramacionDefault().toISOString()}
                                  name={`${name}[${index}].finProgramacion`}
                                  disabled={disabled}
                                  label={i18n('entities.planificadorTarea.fields.finProgramacion')}
                                  required={false}
                                />
                              </Grid>
                            </React.Fragment>
                          ) : null}

                          {watch(`${name}[${index}].programar`) &&
                            watch(`${name}[${index}].inicioProgramacion`) &&
                            watch(`${name}[${index}].finProgramacion`) && (
                              <React.Fragment>
                                <Grid item lg={8} md={8} sm={12} xs={12}>
                                  <RadioFormItem
                                    name={`${name}[${index}].tipoPeriodicidad`}
                                    disabled={disabled}
                                    label={i18n('entities.planificadorTarea.fields.tipoPeriodicidad')}
                                    options={planificadorTareaEnumerators.tipoPeriodicidad.map((value) => ({
                                      value,
                                      label: i18n(`entities.planificadorTarea.enumerators.tipoPeriodicidad.${value}`),
                                    }))}
                                    required={false}
                                  />
                                </Grid>
                                <Grid item lg={8} md={8} sm={12} xs={12}>
                                  <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                                    <Box>Repetir cada</Box>
                                    <Box style={{maxWidth: '110px'}}>
                                      <InputNumberFormItem
                                        name={`${name}[${index}].periodicidad`}
                                        disabled={disabled}
                                        label={i18n('entities.planificadorTarea.fields.periodicidad')}
                                        //placeholder={'¿Cada cuanto tiempo?'}
                                        required={true}
                                      />
                                    </Box>
                                    {watch(`${name}[${index}].tipoPeriodicidad`) && (
                                      <Box>
                                        {' '}
                                        {i18n(
                                          `entities.planificadorTarea.enumerators.tipoPeriodicidad.${watch(
                                            `${name}[${index}].tipoPeriodicidad`,
                                          )}`,
                                        ).toLowerCase()}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                                {watch(`${name}[${index}].tipoPeriodicidad`) === 'weeks' && (
                                  <Grid item lg={12} md={8} sm={12} xs={12}>
                                    <DayOfWeekPicker
                                      name={`${name}[${index}].daysOfWeek`}
                                      onChange={(value) => onChangeDaysOfWeek(index, value, item)}
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            )}
                          {asignarHoraInicio && (
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                              <Grid container>
                                <Grid item style={{paddingRight: 20}}>
                                  <DatePickerFormItem
                                    timeOnly
                                    minDate={() => calcMinDate(item)}
                                    //timeType={'24hrs'}
                                    //outputType={'date'}
                                    defaultValue={getStartOfTask(index)}
                                    name={`${name}[${index}].horaInicio`}
                                    disabled={disabled}
                                    label={i18n('entities.planificadorTarea.fields.horaInicio')}
                                    required={false}
                                    onChange={(value) => onChangeHoraInicio(index, value, item)}
                                  />
                                  {/* <InputTimeFormItem
                                    minTime={() => calcMinDate(item)}
                                    timeType={'24hrs'}
                                    outputType={'date'}
                                    defaultValue={getStartOfTask(index)}
                                    name={`${name}[${index}].horaInicio`}
                                    disabled={disabled}
                                    label={i18n('entities.planificadorTarea.fields.horaInicio')}
                                    required={false}
                                    onChange={(value) => onChangeHoraInicio(index, value, item)}
                                  /> */}
                                </Grid>
                                <Grid item>
                                  <InputNumberFormItem
                                    disableFormat
                                    name={`${name}[${index}].tiempoEstimado`}
                                    disabled={disabled}
                                    label={i18n('entities.planificadorTarea.fields.tiempoEstimado')}
                                    required={false}
                                    //onChange={(value) => onChangeTiempoEstimado(index, value, item)}
                                    onBlur={(value) => {
                                      value = getValues(`${name}[${index}].tiempoEstimado`);
                                      onChangeTiempoEstimado(index, value, item);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item style={{paddingRight: 20}}>
                                  <DatePickerFormItem
                                    timeOnly
                                    minDate={() => getStartOfTask(index)}
                                    //timeType={'24hrs'}
                                    //outputType={'date'}
                                    defaultValue={getEndOfTask(index)}
                                    name={`${name}[${index}].horaFin`}
                                    disabled={disabled}
                                    label={i18n('entities.planificadorTarea.fields.horaFin')}
                                    required={false}
                                    onChange={(value) => onChangeHoraFin(index, value, item)}
                                  />
                                  {/* <InputTimeFormItem
                                    minTime={() => getStartOfTask(index)}
                                    timeType={'24hrs'}
                                    outputType={'date'}
                                    defaultValue={getEndOfTask(index)}
                                    name={`${name}[${index}].horaFin`}
                                    disabled={disabled}
                                    label={i18n('entities.planificadorTarea.fields.horaFin')}
                                    required={false}
                                    onChange={(value) => onChangeHoraFin(index, value, item)}
                                  /> */}
                                </Grid>
                                <Grid item style={{alignSelf: 'center'}}>
                                  {/* tooltip */}
                                  <Tooltip followCursor title="Reasignar hora de inicio y fin a las tareas sucesoras">
                                    <Button
                                      variant="outlined"
                                      startIcon={<Redo />}
                                      onClick={() => {
                                        asignarHoraInicioFinTareasSucesoras(index, item);
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </React.Fragment>
                      )}
                  </React.Fragment>
                )}

                {/* {!fromPlanificacion && !props.disabled && (
                  <Grid item style={{marginTop: 40}} lg={12}>
                    <Button
                      size={'small'}
                      variant="contained"
                      color={'secondary'}
                      type="button"
                      onClick={() => doConfirm()}
                      style={{backgroundColor: 'red'}}>
                      <CloseIcon /> Eliminar Programación
                    </Button>
                  </Grid>
                )} */}

                {confirm && (
                  <ConfirmModal
                    title={i18n('common.areYouSure')}
                    onConfirm={() => {
                      remove(index);
                      setConfirm(false);
                    }}
                    onClose={() => doCloseConfirm()}
                    okText={i18n('common.yes')}
                    cancelText={i18n('common.no')}
                  />
                )}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      {!fromPlanificacion && !props.disabled && (
        <div style={{paddingLeft: 20}}>
          <Button variant="outlined" color={'primary'} style={{marginTop: 20}} type="button" onClick={() => append({})}>
            + Añadir Programación
          </Button>
        </div>
      )}
    </Box>
  );
}
