import {ArrowDownward, ArrowUpward, Delete, FileCopy} from '@mui/icons-material';
import {Box, Grid, IconButton, styled} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import UnidadMedidaAutocompleteFormItem from 'src/view/unidadMedida/autocomplete/UnidadMedidaAutocompleteFormItem';

import {Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment-timezone';
import {i18n} from 'src/i18n';
import incidentEnumerators from 'src/modules/incident/incidentEnumerators';
import CustomAccordion from 'src/view/shared/CustomAccordion';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';
import TagsFormItem from 'src/view/shared/form/items/TagsFormItem';
import {styles as materialUiStyles} from 'src/view/shared/form/items/shared/reactSelectMaterialUi';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';

const useMaterialStyles = makeStyles(materialUiStyles as any);

const OnHoverBox = styled(Box)`
  display: flex;
  flexdirection: row;
  gap: 8px;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`;

export default function PreguntaFormularioForm(props) {
  const {
    fieldsLength,
    expanded,
    handleChangeAccordion,
    handleExitedAccordion,
    append,
    insert,
    remove,
    move,
    item,
    indexPregunta,
    name,
    control,
    register,
  } = props;
  const {getValues, setValue} = useFormContext();

  //console.log('%c⧭ PreguntaFormularioForm: pregunta', 'color: #7f2150', item, getValues(name));
  item.nPregunta = indexPregunta + 1;
  useEffect(() => {
    register(`${name}.nPregunta`);
    register(`${name}.pregunta`);
    register(`${name}.tipoRespuesta`);
    register(`${name}.link`);
    register(`${name}.tieneLink`);
    register(`${name}.tieneComentario`);
    register(`${name}.tienePuntaje`);
    register(`${name}.puntaje`);
    register(`${name}.unidadMedida`);
    register(`${name}.alternativasCheckbox`);
    register(`${name}.alternativas`);
    register(`${name}.respuestaCorrecta`);
    register(`${name}.allowDateBefore`);
    register(`${name}.restrictByLocation`);
    register(`${name}.haveCriticality`);
    register(`${name}.havePhotos`);
    register(`${name}.haveFiles`);

    register(`${name}.keyPointer`); // El campo de modulo incidentes donde se guardará la respuesta al responder el formulario

    register(`${name}.multipleAnswersType`);
    register(`${name}.allowMultipleAnswers`);
    register(`${name}.onlyOneOption`);
    register(`${name}.allowOthersOption`);
    register(`${name}.isRequired`);

    // set values //
    setValue(`${name}.nPregunta`, indexPregunta + 1);
    setValue(`${name}.tieneLink`, item.tieneLink || (item.link ? true : false));
    setValue(`${name}.link`, item.link || '');
    if (item.pregunta) {
      const tituloPregunta = item.pregunta.titulo || item.pregunta.label;
      setValue(`${name}.pregunta.titulo`, tituloPregunta);
      setValue(`${name}.pregunta.label`, tituloPregunta);
    }
    if (!item.tipoRespuesta) {
      setValue(`${name}.tipoRespuesta`, 'textField');
    }
  }, []);

  let tipoRespuestaOptions = [
    {
      value: 'textField',
      label: 'Campo de Texto',
    },
    {
      value: 'numberField',
      label: 'Campo de Número',
    },
    {
      value: 'multipleChoice',
      label: 'Lista Desplegable',
    },
    {
      value: 'multiCheckbox',
      label: 'Lista de Checkbox',
    },
    {
      value: 'checkbox',
      label: 'Checkbox',
    },
    {
      value: 'inspectionCheck',
      label: 'Inspección',
    },
    {
      value: 'yesNo',
      label: 'Si, No, N/A',
    },
    {
      value: 'ammount',
      label: 'Monto',
    },
    {
      value: 'registrationNumber',
      label: 'RUT',
    },
    {
      value: 'barcode',
      label: 'Código de Barra',
    },
    {
      value: 'date',
      label: 'Fecha',
    },
    {
      value: 'time',
      label: 'Hora',
    },
    {
      value: 'day',
      label: 'Día',
    },
    {
      value: 'month',
      label: 'Mes',
    },
    {
      value: 'year',
      label: 'Año',
    },
    {
      value: 'location',
      label: 'Ubicación',
    },
    {
      value: 'thirdParty',
      label: 'Tercero',
    },
    {
      value: 'asset',
      label: 'Activo',
    },
    {
      value: 'perfilEmpleado',
      label: 'Colaborador',
    },
    {
      value: 'files',
      label: 'Foto / Documento',
    },
    {
      value: 'textFromImage',
      label: 'Texto desde Imagen',
    },
    {
      value: 'signature',
      label: 'Firma',
    },
    {
      value: 'meterReading',
      label: 'Lectura de Medidor',
    },
  ];
  const mapIncidentEnum = (name, options) => {
    return options.map((option) => ({
      id: option,
      label: i18n(`entities.incident.enumerators.${name}.${option}`),
    }));
  };
  const tipoRespuestaIncidenteOptions = [
    {
      value: 'incident_incidentCode',
      label: i18n('entities.incident.fields.incidentCode') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_withLostDays',
      label: i18n('entities.incident.fields.withLostDays') + ` (${i18n('entities.incident.name')})`,
      responseType: 'checkbox',
    },
    {
      value: 'incident_initialClassification',
      label: i18n('entities.incident.fields.initialClassification') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
      subOptions: mapIncidentEnum('initialClassification', incidentEnumerators.initialClassification),
    },
    {
      value: 'incident_finalClassification',
      label: i18n('entities.incident.fields.finalClassification') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
      subOptions: mapIncidentEnum('finalClassification', incidentEnumerators.finalClassification),
    },
    {
      value: 'incident_severity',
      label: i18n('entities.incident.fields.severity') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
      subOptions: mapIncidentEnum('severity', incidentEnumerators.severity),
    },
    {
      value: 'incident_label',
      label: i18n('entities.incident.fields.label') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_dateFrom',
      label: i18n('entities.incident.fields.dateFrom') + ` (${i18n('entities.incident.name')})`,
      responseType: 'date',
    },
    {
      value: 'incident_dateFromTime',
      label: i18n('entities.incident.fields.dateFromTime') + ` (${i18n('entities.incident.name')})`,
      responseType: 'time',
    },
    {
      value: 'incident_dateTo',
      label: i18n('entities.incident.fields.dateTo') + ` (${i18n('entities.incident.name')})`,
      responseType: 'date',
    },
    {
      value: 'incident_responsibleAdvisor',
      label: i18n('entities.incident.fields.responsibleAdvisor') + ` (${i18n('entities.incident.name')})`,
      responseType: 'perfilEmpleado',
    },
    {
      value: 'incident_incidentLocation',
      label: i18n('entities.incident.fields.incidentLocation') + ` (${i18n('entities.incident.name')})`,
      responseType: 'location',
    },
    {
      value: 'incident_incidentDescription',
      label: i18n('entities.incident.fields.incidentDescription') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_narrativeAccount',
      label: i18n('entities.incident.fields.narrativeAccount') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_incidentStatus',
      label: i18n('entities.incident.fields.incidentStatus') + ` (${i18n('entities.incident.name')})`,
      responseType: 'multipleChoice',
      subOptions: mapIncidentEnum('incidentStatus', incidentEnumerators.incidentStatus),
    },
    {
      value: 'incident_company',
      label: i18n('entities.incident.fields.company') + ` (${i18n('entities.incident.name')})`,
      responseType: 'textField',
    },
    {
      value: 'incident_employees',
      label: i18n('entities.incident.fields.employees') + ` (${i18n('entities.incident.name')})`,
      responseType: 'perfilEmpleado',
    },
  ];
  const clasificacionValue = useWatch({name: 'clasificacion', control});
  const watchKeyPointer = useWatch({name: `${name}.keyPointer`, control, defaultValue: item.keyPointer});
  const watchTipoRespuesta = useWatch({name: `${name}.tipoRespuesta`, control});
  //console.log('%c⧭ watchTipoRespuesta', 'color: #735656', watchTipoRespuesta);
  if (clasificacionValue === 'incidente') {
    tipoRespuestaIncidenteOptions.forEach((incidentOption) => {
      if ('incident_' + watchKeyPointer === incidentOption.value) {
        if (watchTipoRespuesta !== incidentOption.value) {
          //console.log('%c⧭', 'color: #ffcc00', 'Convert', watchTipoRespuesta, 'To', incidentOption.value);
          setValue(`${name}.tipoRespuesta`, incidentOption.value);
        }
      }
    });
    tipoRespuestaOptions = tipoRespuestaOptions.concat(tipoRespuestaIncidenteOptions);
  }
  const watchName = useWatch({name: `${name}`});
  //console.log('%c⧭ pregunta', 'color: #e50000', {watchName});
  const watchTienePuntaje = useWatch({name: `${name}.tienePuntaje`, control, defaultValue: item.tienePuntaje});
  const watchTieneLink = useWatch({name: `${name}.tieneLink`, control, defaultValue: item.tieneLink || !!item.link});
  const watchPregunta = useWatch({name: `${name}.pregunta`, control, defaultValue: item.pregunta});
  // const watchLink = useWatch({name: `${name}.link`, control, defaultValue: item.link});
  // console.log('%c⧭', 'color: #408059', {watchLink});

  const [recordToDelete, setRecordToDelete] = useState(null);

  const doCloseDeleteConfirmModal = () => {
    setRecordToDelete(null);
  };

  const doDeleteRecord = (index) => {
    remove(index);
    doCloseDeleteConfirmModal();
  };

  const doOpenDeleteConfirmModal = (index) => {
    setRecordToDelete(index);
  };

  const [recordToCopy, setRecordToCopy] = useState(null);

  const doCloseCopyConfirmModal = () => {
    setRecordToCopy(null);
  };

  const doCopyJob = async (original) => {
    const pregunta = {...original};
    const newTitulo = pregunta.pregunta?.titulo + ' Copia ' + moment().format('DD-MM-YYYY HH:mm:ss');
    pregunta.pregunta.titulo = newTitulo;
    pregunta.pregunta.label = newTitulo;
    delete pregunta.key;
    delete pregunta.id;
    append(pregunta);
    doCloseCopyConfirmModal();
  };

  const doOpenCopyConfirmModal = () => {
    const pregunta = getValues(`${name}`);
    setRecordToCopy(pregunta);
  };

  const moveUp = (index) => {
    if (index === 0) {
      return;
    }
    move(index, index - 1);
  };

  const moveDown = (index) => {
    if (index === fieldsLength - 1) {
      return;
    }
    move(index, index + 1);
  };

  const customSelectInput = ({
    label = undefined,
    name = undefined,
    isDisabled = false,
    getOptionValue = (option) => option.id,
    createOption = (newValue) => ({
      id: newValue.toLowerCase().replace(/\W/g, ''),
      label: newValue,
    }),
    endMargin = '43px',
  }: any) => {
    return (
      <>
        <div style={{display: 'flex', alignItems: 'start'}}>
          <div style={{width: '100%'}}>
            <TagsFormItem
              label={label}
              name={name}
              disabled={isDisabled}
              notFoundContent={i18n('common.writeAlternative')}
              getOptionValue={getOptionValue}
              createOption={createOption}
            />
          </div>
          <div
            style={{
              width: endMargin,
            }}
          />
        </div>
      </>
    );
  };

  const renderQuestionSummary = (item, preguntaIndex) => {
    return (
      <div style={{display: 'inline-flex', width: '100%', cursor: 'default'}}>
        <div
          style={{
            width: '82%',
            //backgroundColor: 'rgb(255,255,255,.725)',
            borderRadius: '5px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '4px',
            marginBottom: 10,
          }}>
          <div onClick={($e) => $e.stopPropagation()}>
            <InputFormItem
              onChange={(newValue) => {
                //console.log('%c⧭ InputFormItem newValue', 'color: #00e600', newValue);
                setValue(`${name}.pregunta.label`, newValue);
              }}
              name={`${name}.pregunta.titulo`}
              defaultValue={item.pregunta?.titulo}
              label={'Pregunta ' + (preguntaIndex + 1)}
              variant="outlined"
              fontSize="1.15rem"
              labelFontSize="1.05rem"
              shrinkLabel={false}
            />
          </div>
        </div>
        <OnHoverBox style={{marginLeft: 'auto', alignSelf: 'center'}} onClick={($e) => $e.stopPropagation()}>
          <Tooltip style={{marginLeft: 10}} followCursor placement={'top-start'} title={i18n('common.copy')}>
            <IconButton size="small" color="primary" onClick={(_) => doOpenCopyConfirmModal()}>
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.moveDown')}>
            <IconButton size="small" color="primary" onClick={() => moveDown(preguntaIndex)}>
              <ArrowDownward />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.moveUp')}>
            <IconButton size="small" color="primary" onClick={() => moveUp(preguntaIndex)}>
              <ArrowUpward />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.destroy')}>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                doOpenDeleteConfirmModal(preguntaIndex);
              }}>
              <Delete />
            </IconButton>
          </Tooltip>
        </OnHoverBox>
      </div>
    );
  };

  const materialClasses = useMaterialStyles();

  return (
    <>
      <CustomAccordion
        key={item.id}
        item={item}
        index={indexPregunta}
        uniqueKey={item.id}
        entityName="Pregunta"
        renderSummary={renderQuestionSummary}
        renderDetails={() => (
          <Grid
            container
            spacing={1}
            style={
              {
                //zoom: 1.05
              }
            }>
            {/* <Grid>
                <Grid item lg={12}>
                  <Grid style={{marginBottom: 15}} item>
                    <label style={{fontWeight: 'bold', fontSize: 14}}>Pregunta:</label>
                  </Grid>

                  <Grid style={{marginBottom: 15}} item>
                    <label style={{fontSize: 16, fontWeight: 700, marginTop: 10}}>
                      {watchPregunta?.label || watchPregunta?.titulo || ''}
                    </label>
                  </Grid>

                  <PreguntaAutocompleteFormItem
                    autoFocus={!watchPregunta?.id}
                    defaultValue={watchPregunta}
                    name={`${name}.pregunta`}
                    size={'small'}
                    showCreate={true}
                  />
                </Grid>
              </Grid> */}

            {/* <Grid container lg={12}>
            <Grid item>
              <SwitchFormItem name={`${name}.allowMultipleAnswers`} size={'medium'} />
            </Grid>
            <Grid item style={{alignSelf: 'center', marginTop: 5}}>
              <label style={{fontWeight: 'bold', fontSize: 16}}>Permitir Multiples Tipos de Respuesta</label>
            </Grid>
          </Grid> */}

            {watchName.allowMultipleAnswers ? (
              <Grid item lg={12}>
                <SelectFormItem
                  label="Tipo de Respuesta"
                  mode="multiple"
                  name={`${name}.multipleAnswersType`}
                  options={tipoRespuestaOptions}
                  endButtonMargin="43px"
                />
              </Grid>
            ) : (
              <Grid item lg={12}>
                <SelectFormItem
                  label="Tipo de Respuesta"
                  onChange={(value) => {
                    const tipoRespuestaIncidente = tipoRespuestaIncidenteOptions.find((item, index) => value == item.value);
                    if (tipoRespuestaIncidente) {
                      //console.log('%c⧭ tipoRespuestaIncidente selected', 'color: #1d3f73', tipoRespuestaIncidente);
                      setValue(`${name}.keyPointer`, tipoRespuestaIncidente.value);
                      setValue(`${name}.alternativas`, tipoRespuestaIncidente.subOptions);
                      setValue(`${name}.isMultiple`, tipoRespuestaIncidente.value === 'incident_employees');
                    } else {
                      setValue(`${name}.keyPointer`, null);
                    }
                  }}
                  name={`${name}.tipoRespuesta`}
                  options={tipoRespuestaOptions}
                  endButtonMargin="43px"
                />
              </Grid>
            )}

            {['location', 'thirdParty', 'asset', 'perfilEmpleado'].includes(watchTipoRespuesta) && (
              <Grid item lg={12}>
                <CheckboxFormItem label="Selección múltiple" name={`${name}.isMultiple`} size={'medium'} />
                <CheckboxFormItem label="Restringir por Ubicación" name={`${name}.restrictByLocation`} size={'medium'} />
              </Grid>
            )}

            {/* {['location', 'thirdParty', 'perfilEmpleado'].includes(watchTipoRespuesta) && (
              <Grid item lg={12}>
                <CheckboxFormItem label="Restringir por Ubicación" name={`${name}.restrictByLocation`} size={'medium'} />
              </Grid>
            )} */}

            {((watchTipoRespuesta === 'meterReading' && !watchName.allowMultipleAnswers) ||
              (watchName.multipleAnswersType?.includes('meterReading') && watchName.allowMultipleAnswers)) && (
              <Grid item lg={12}>
                <UnidadMedidaAutocompleteFormItem
                  label="Seleccione Unidad de Medida a Medir"
                  name={`${name}.unidadMedida`}
                  size={'small'}
                  showCreate={true}
                />
              </Grid>
            )}

            {((watchTipoRespuesta === 'multiCheckbox' && !watchName.allowMultipleAnswers) ||
              (watchName.multipleAnswersType?.includes('multiCheckbox') && watchName.allowMultipleAnswers)) && (
              <Grid item lg={12}>
                {customSelectInput({
                  label: 'Alternativas de Checkbox',
                  name: `${name}.alternativasCheckbox`,
                })}
                {/* <RespuestaAutocompleteFormItem
                  label="Alternativas de Checkbox"
                  name={`${name}.alternativasCheckbox`}
                  size={'small'}
                  showCreate={true}
                  mode="multiple"
                /> */}
              </Grid>
            )}

            {tipoRespuestaIncidenteOptions.map((item, index) => {
              if (item.value === watchTipoRespuesta && item.responseType === 'multipleChoice') {
                return (
                  <React.Fragment key={index}>
                    {item.responseType === 'multipleChoice' && (
                      <Grid item lg={12}>
                        {customSelectInput({
                          label: 'Alternativas de Respuestas',
                          name: `${name}.alternativas`,
                          isDisabled: true,
                        })}
                      </Grid>
                    )}
                  </React.Fragment>
                );
              }
            })}

            {((watchTipoRespuesta === 'multipleChoice' && !watchName.allowMultipleAnswers) ||
              (watchName.multipleAnswersType?.includes('multipleChoice') && watchName.allowMultipleAnswers)) && (
              <React.Fragment>
                {/* <Grid item lg={12}>
                  <RespuestaAutocompleteFormItem
                    label="Respuesta Correcta"
                    name={`${name}.respuestaCorrecta`}
                    size={'small'}
                    showCreate={true}
                  />
                </Grid> */}
                <Grid item lg={12}>
                  {customSelectInput({
                    label: 'Alternativas de Respuestas',
                    name: `${name}.alternativas`,
                  })}
                </Grid>
              </React.Fragment>
            )}

            {((watchTipoRespuesta === 'multipleChoice' && !watchName.allowMultipleAnswers) ||
              (watchName.multipleAnswersType?.includes('multipleChoice') && watchName.allowMultipleAnswers) ||
              (watchTipoRespuesta === 'multiCheckbox' && !watchName.allowMultipleAnswers) ||
              (watchName.multipleAnswersType?.includes('multiCheckbox') && watchName.allowMultipleAnswers)) && (
              <>
                {((watchTipoRespuesta === 'multiCheckbox' && !watchName.allowMultipleAnswers) ||
                  (watchName.multipleAnswersType?.includes('multiCheckbox') && watchName.allowMultipleAnswers)) && (
                  <Grid item lg={12}>
                    <CheckboxFormItem label={i18n('common.uniqueOption')} name={`${name}.onlyOneOption`} size={'medium'} />
                  </Grid>
                )}
                <Grid item lg={12}>
                  <CheckboxFormItem label={i18n('common.allowOptionOthers')} name={`${name}.allowOthersOption`} size={'medium'} />
                </Grid>
              </>
            )}

            {(([
              'date',
              ...tipoRespuestaIncidenteOptions.filter((tr) => tr.responseType === 'date').map((tr) => tr.value),
            ].includes(watchTipoRespuesta) &&
              !watchName.allowMultipleAnswers) ||
              (watchName.multipleAnswersType?.includes('date') && watchName.allowMultipleAnswers)) && (
              <Grid item lg={12}>
                <CheckboxFormItem label={i18n('common.allowOlderDates')} name={`${name}.allowDateBefore`} size={'medium'} />
              </Grid>
            )}

            <Grid item lg={12}>
              <div style={{border: '1px solid rgb(0,0,0,.225', borderRadius: 5, marginTop: 10, marginBottom: 16}}>
                <CheckboxFormItem label={i18n('common.answerRequired')} name={`${name}.isRequired`} size={'medium'} />
                <CheckboxFormItem label={i18n('common.attachPhotos')} name={`${name}.havePhotos`} size={'medium'} />
                <CheckboxFormItem label={i18n('common.attachDocuments')} name={`${name}.haveFiles`} size={'medium'} />
                {['files', 'textFromImage'].includes(watchTipoRespuesta) && (
                  <>
                    {watchTipoRespuesta === 'files' && (
                      <CheckboxFormItem label={i18n('common.onlyImages')} name={`${name}.imagesOnly`} size={'medium'} />
                    )}
                    <CheckboxFormItem label={i18n('common.onlyFromCamera')} name={`${name}.cameraOnly`} size={'medium'} />
                  </>
                )}
                <CheckboxFormItem label={i18n('common.addComment')} name={`${name}.tieneComentario`} size={'medium'} />
                <CheckboxFormItem label={i18n('common.addCriticality')} name={`${name}.haveCriticality`} size={'medium'} />
                {/* <div style={{marginTop: -11, display: 'inline-flex', alignItems: 'center', marginRight: 16}}>
                  <div style={{width: '100%', marginRight: -20}}>
                    <CheckboxFormItem label="Asignar Puntaje" name={`${name}.tienePuntaje`} size={'medium'} />
                  </div>
                  {watchTienePuntaje ? (
                    <InputNumberFormItem
                      label="Puntaje"
                      name={`${name}.puntaje`}
                      defaultValue={item.puntaje}
                      placeholder="Puntaje Pregunta"
                    />
                  ) : null}
                </div> */}
                <div
                  style={{
                    marginTop: -11,
                    display: 'inline-flex',
                    alignItems: 'center',
                    width: '-webkit-fill-available',
                    maxWidth: '90%',
                  }}>
                  <div style={{textWrap: 'nowrap'} as any}>
                    <CheckboxFormItem label="Adjuntar un enlace" name={`${name}.tieneLink`} size={'medium'} />
                  </div>
                  {watchTieneLink ? (
                    <div style={{width: '-webkit-fill-available'}}>
                      <InputFormItem
                        label="Link"
                        name={`${name}.link`}
                        defaultValue={item.link}
                        placeholder="https://www.ejemplo.com"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Grid>
            {/* <Grid item lg={12}>
              <InputFormItem label="Link" name={`${name}.link`} defaultValue={item.link} placeholder="https://www.ejemplo.com" />
            </Grid> */}
          </Grid>
        )}
        expanded={expanded}
        //detailTitle={`${item.nPregunta}. ${watchPregunta?.label || watchPregunta?.titulo || ''}`}
        handleChange={handleChangeAccordion}
        topButtonClick={(item, index) => {
          insert(index);
        }}
        bottomButtonClick={(item, index) => {
          insert(index + 1);
        }}
      />
      {recordToCopy && (
        <ConfirmModal
          title={i18n('common.areYouSureCopyPregunta')}
          onConfirm={() => doCopyJob(recordToCopy)}
          onClose={() => doCloseCopyConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
      {(recordToDelete || recordToDelete === 0) && (
        <ConfirmModal
          title={i18n('common.areYouSureDelete')}
          onConfirm={() => doDeleteRecord(recordToDelete)}
          onClose={() => doCloseDeleteConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </>
  );
}
