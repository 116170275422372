import {CalendarTodayOutlined, Remove as RemoveIcon, Search as SearchIcon} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import MoveDownIcon from '@mui/icons-material/ArrowDownward';
import MoveUpIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import _uniqBy from 'lodash/uniqBy';
import React, {useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import AsyncSelect from 'react-select/async';
import {i18n} from 'src/i18n';
import ModeloTareaService from 'src/modules/modeloTarea/modeloTareaService';
import PlanificacionService from 'src/modules/planificacion/planificacionService';
import ModeloTareaView from 'src/view/modeloTarea/view/ModeloTareaView';
import PlanificadorTareaFormPage from 'src/view/planificadorTarea/form/PlanificadorTareaFormPage';
import Spinner from 'src/view/shared/Spinner';
import FormErrors from 'src/view/shared/form/formErrors';
import {
  components as materialUiComponents,
  styles as materialUiStyles,
} from 'src/view/shared/form/items/shared/reactSelectMaterialUi';
import Message from 'src/view/shared/message';
import ToolbarWrapper from 'src/view/shared/styles/ToolbarWrapper';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';
import ClasificacionTagView from 'src/view/shared/view/ClasificacionTagView';
import DrawerButton from 'src/view/shared/view/DrawerButton';

import modeloTareaSelectors from 'src/modules/modeloTarea/modeloTareaSelectors';
import ModeloTareaFormModal from 'src/view/modeloTarea/form/ModeloTareaFormModal';
import ModeloTareaFormPage from 'src/view/modeloTarea/form/ModeloTareaFormPage';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';

const PlanificacionDependencyView = (props) => {
  const {label, name, depName, hint, externalErrorMessage, required, disabled, defaultValue, defaultDep, isForm, planificacion} =
    props;

  const hasPermissionToCreate = useSelector(modeloTareaSelectors.selectPermissionToCreate);

  const AUTOCOMPLETE_SERVER_FETCH_SIZE = 100;

  const {
    control,
    setValue,
    register,
    formState: {touchedFields, isSubmitted, errors},
    getValues,
  } = useFormContext();

  // Sort Array by given order key
  const sortArrayByKey = (array, key = 'order') => array.sort((a, b) => a[key] - b[key]);

  // Agregar información de las dependencias al array de las tareas
  const MapWithDepInfo = (tareas, dep = dependency) =>
    sortArrayByKey(
      tareas.map((t, i) => ({
        ...t, // modeloTarea
        order: i + 1, //default
        successors: [], //default
        predecessors: [], //default
        ...dep?.find((d) => d.id === t.id),
      })),
    );

  const dependency: any = useWatch({name: depName, control, defaultValue: defaultDep});
  const originalValue: any = useWatch({name, control, defaultValue: MapWithDepInfo(defaultValue)});
  const [fullDataSource, setFullDataSource] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>(originalValue);
  const hasRows = !!rows.length;

  const toggleTareaSelected = (id) => {
    let selected = false;
    let newRows = rows.map((row) => {
      if (row.id === id) {
        selected = !row.selected;
        return {...row, selected};
      }
      return row;
    });
    if (selected) newRows = selectPredecessors(id, newRows);
    else newRows = deselectSuccessors(id, newRows);
    setRows(newRows);
  };

  const allSelected = () => rows.filter((r) => r.selected).length === rows.length;

  const toggleAllTareasSelected = () => {
    const selected = !allSelected();
    const newRows = rows.map((row) => ({...row, selected}));
    setRows(newRows);
  };

  const fetchFn = (value?, limit?) => {
    return ModeloTareaService.listAutocomplete({filter: props.filter, value}, limit);
  };

  const useStyles = makeStyles(materialUiStyles as any);

  useEffect(() => {
    register(name);
    setValue(name, originalValue);
  }, []);

  const getDataSource = async () => {
    try {
      let fullDataSource = await fetchFn();
      //fullDataSource = fullDataSource.map((data) => mapper.toValue(data));
      setFullDataSource(fullDataSource);
    } catch (error) {
      console.error(error);
      setFullDataSource([]);
      setLoading(false);
      return [];
    }
  };

  // Obtener las tareas de la planificación
  const getPlanificacion = async () => {
    if (!planificacion) return;
    setLoading(true);
    let result = await PlanificacionService.find(planificacion.id);
    if (result?.tareas) {
      const tareas = MapWithDepInfo(result.tareas, result.dependency);
      console.log('%c⧭ setRows', 'color: #735656', {tareas});
      setRows(tareas);
      setValue(name, tareas);
      setValue('sequentialExecutionRequired', result?.sequentialExecutionRequired);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDataSource();
    if (!isForm) getPlanificacion();
    // eslint-disable-next-line
  }, []);

  // const prioritizeFromDataSource = (selected) => (fullDataSource || []).find((item) => item.value === selected?.value) || selected;
  // const value = () => originalValue && prioritizeFromDataSource(originalValue);

  const handleDependency = (newRows) => {
    const dependency: any = [];
    for (let i = 0; i < newRows.length; i++) {
      const tarea = newRows[i];
      dependency.push({
        id: tarea.id,
        order: i + 1,
        predecessors: tarea.predecessors,
        successors: tarea.successors,
        type: 0,
      });
    }
    setValue(depName, dependency);
    if (!isForm) {
      PlanificacionService.update(planificacion.id, {tareas: newRows.map((r) => r.id), dependency});
    }
  };

  const handleChange = (value) => {
    setValue(name, value, {shouldValidate: true});
    handleDependency(value);
    props.onChange && props.onChange(value);
  };

  const moveUp = (id) => {
    const index = rows.findIndex((row) => row.id === id);
    if (index > 0) {
      const temp = rows[index];
      rows[index] = rows[index - 1];
      rows[index - 1] = temp;
      setRows(rows.map((r, i) => ({...r, order: i + 1})));
    }
    handleChange(rows);
  };

  const moveDown = (id) => {
    const index = rows.findIndex((row) => row.id === id);
    if (index < rows.length - 1) {
      const temp = rows[index];
      rows[index] = rows[index + 1];
      rows[index + 1] = temp;
      setRows(rows.map((r, i) => ({...r, order: i + 1})));
    }
    handleChange(rows);
  };

  const remove = (id) => {
    const newRows = rows.filter((row) => row.id !== id).map((r, i) => ({...r, order: i + 1}));
    setRows(newRows);
    handleChange(newRows);
  };

  const handleSelect = (value) => {
    const newRows = _uniqBy(
      [
        ...rows,
        {
          ...value.record,
          order: rows.length + 1,
          predecessors: [],
          successors: [],
        },
      ],
      'id',
    );
    setRows(newRows);
    handleChange(newRows);
  };

  // Buscar tareas que no sean predecesoras de la actual, sucesoras de la actual, ni la misma tarea actual
  const handleSearchPredecessors = (tareaId) => async (value) => {
    const tarea = rows.find((r) => r.id === tareaId);
    const {predecessors, successors} = tarea;
    return rows.filter(
      (r) =>
        r.id !== tareaId &&
        !predecessors.includes(r.id) &&
        !successors.includes(r.id) &&
        r.titulo.toLowerCase().includes(value.toLowerCase()),
    );
  };

  // quitar todos los predecesores de una tarea
  const removeAllPredecessorsFromTask = (tareaId) => {
    const newRows = rows.map((r) => {
      if (r.id === tareaId) return {...r, predecessors: []};
      return r;
    });
    setRows(newRows);
    handleChange(newRows);
  };

  // quitar los que causan la dependencia circular
  const removeCircleMakersFromTask = (task, circleMakers) => {
    const {predecessors} = task;
    // get both removed and not removed predecessors
    const {removed, notRemoved} = predecessors.reduce(
      (acc, p) => {
        if (circleMakers.includes(p)) acc.removed.push(p);
        else acc.notRemoved.push(p);
        return acc;
      },
      {removed: [], notRemoved: []},
    );
    // remove the task from the successors of the removed predecessors and remove the removed predecessors from the task
    const newRows = rows.map((r) => {
      if (removed.includes(r.id)) return {...r, successors: r.successors.filter((s) => s !== task.id)};
      if (r.id === task.id) return {...r, predecessors: notRemoved};
      return r;
    });
    setRows(newRows);
    handleChange(newRows);
  };

  // check if any of the predecessors is a successor of the current task recursively
  const checkCircleDependency = (tareaId, predecessors, successor = null) => {
    if (tareaId === successor) {
      console.log('%c⧭', 'color: #d90000', 'THERE WAS ALREADY A CIRCULAR DEPENDENCY REMOVING ALL', {
        tareaId,
        predecessors,
        successor,
      });
      removeAllPredecessorsFromTask(tareaId);
      return true;
    }
    if (!successor) successor = tareaId;
    const tarea = rows.find((r) => r.id === successor);
    if (!tarea) return false;
    const {successors} = tarea;
    console.log('%c⧭ checkCircleDependency', 'color: #7f7700', {tarea, predecessors, successors});
    const circleMakers = successors.filter((s) => predecessors.includes(s));
    if (circleMakers.length > 0) {
      const tareaInicial = rows.find((r) => r.id === tareaId);
      console.log('%c⧭', 'color: #917399', {circleMakers});
      console.error(`La tarea no puede ser sucesora de ${tareaInicial.titulo}, porque crea una dependencia circular`);
      Message.error(`La tarea no puede ser sucesora de ${tareaInicial.titulo}, porque crea una dependencia circular`);
      removeCircleMakersFromTask(tareaInicial, circleMakers);
      return true;
    }
    for (let i = 0; i < successors.length; i++) {
      successor = successors[i];
      if (checkCircleDependency(tareaId, predecessors, successor)) {
        return true;
      }
    }
    return false;
  };

  // Handle predecessor selection change
  const handleSelectPredecessors = (tareaId) => (predecessors) => {
    predecessors = predecessors?.map((p) => p.id) || [];
    // validate circle dependency
    if (checkCircleDependency(tareaId, predecessors)) return;
    // Find the deleted predecessors
    const deletedPredecessors = taskPredecessors(tareaId).filter((p) => !predecessors.includes(p));
    // is selected
    const isSelected = selectedTareas().find((t) => t.id === tareaId);
    let newRows = rows.map((row) => {
      // Set new predecessors
      if (row.id === tareaId) {
        row.predecessors = predecessors;
      }
      // Add new task to successors
      if (predecessors.includes(row.id) && !row.predecessors.includes(tareaId) && !row.successors.includes(tareaId)) {
        //console.log('%c⧭', 'color: #00ff88', tareaId+' -> push to successors of: '+row.titulo);
        //if(isSelected) row.selected = true;
        row.successors.push(tareaId);
      }
      // Remove deleted task from successors
      if (deletedPredecessors.includes(row.id)) {
        //console.log('%c⧭', 'color: #00ff88', tareaId+' -> remove from the successors of: '+row.titulo);
        row.successors = row.successors.filter((s) => s !== tareaId);
      }
      return row;
    });
    if (isSelected) newRows = selectPredecessors(tareaId, newRows);
    setRows(newRows);
    handleChange(newRows);
  };

  const hintOrLoading = loading ? i18n('autocomplete.loading') : hint;

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const handleSearch = async (value) => {
    try {
      const results = await fetchFn(value, AUTOCOMPLETE_SERVER_FETCH_SIZE);
      return results;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const marginOptions = {
    normal: {marginTop: '16px', marginBottom: '8px'},
    none: {marginTop: '0px', marginBottom: '0px'},
  };

  const controlStyles = {
    container: (provided) => ({
      ...provided,
      width: props.maxContent ? 'max-content' : '100%',
      minWidth: hasPermissionToCreate && props.showCreate ? 'calc(100% - 40px)' : '100%',
      ...marginOptions[props.margin || 'normal'],
    }),
    control: (provided) => ({
      ...provided,
      borderColor: Boolean(errorMessage) ? 'red' : undefined,
    }),
  };

  if (props.fixedBase) {
    //controlStyles['menuList'] = base => ({...base, position: 'fixed !important', backgroundColor: 'white', border: '1px solid lightgray', width: '20rem'});
    controlStyles['menuList'] = (base) => ({...base, zIndex: 9999});
  }

  const classes = useStyles();

  const renderSelect = () => {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <AsyncSelect
          styles={controlStyles}
          classes={classes}
          inputId={name}
          TextFieldProps={{
            label,
            variant: 'outlined',
            fullWidth: true,
            error: Boolean(errorMessage),
            helperText: errorMessage || hint,
            size: 'small',
            InputLabelProps: {
              shrink: true,
            },
          }}
          components={materialUiComponents}
          defaultOptions={true}
          isMulti={false}
          loadOptions={handleSearch}
          onChange={handleSelect}
          value={{}}
          isClearable={false}
          loadingMessage={() => i18n('autocomplete.loading')}
          noOptionsMessage={() => i18n('autocomplete.noOptions')}
        />
        {props.showCreate && hasPermissionToCreate ? (
          <IconButton
            style={{
              marginLeft: '0px',
              marginTop: '16px',
              marginBottom: '8px',
              flexShrink: 0,
            }}
            color="secondary"
            onClick={() => setOpenModal(true)}>
            <AddIcon />
          </IconButton>
        ) : null}
      </div>
    );
  };
  const taskById = (tareaId) => rows.find((t) => t.id === tareaId);
  const renderSelectPredecessors = (tareaId) => {
    if (disabled) {
      return (
        <div style={{marginBottom: 10}}>
          <Typography variant="body2" color="textSecondary" component="p">
            {taskPredecessors(tareaId)
              .map((p) => taskById(p)?.titulo || ' ')
              .join(', ')}
          </Typography>
        </div>
      );
    }
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <AsyncSelect
          key={JSON.stringify(rows)}
          styles={controlStyles}
          classes={classes}
          inputId={name + '_' + tareaId}
          TextFieldProps={{
            label: 'Predecesoras',
            variant: 'outlined',
            fullWidth: true,
            error: Boolean(errorMessage),
            helperText: errorMessage || hint,
            size: 'small',
            InputLabelProps: {
              shrink: true,
            },
          }}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.titulo}
          components={materialUiComponents}
          defaultOptions={true}
          isMulti={true}
          loadOptions={handleSearchPredecessors(tareaId)}
          onChange={handleSelectPredecessors(tareaId)}
          placeholder=""
          value={taskPredecessors(tareaId).map((predecessor) => rows.find((r) => r.id === predecessor))}
          isClearable={false}
          loadingMessage={() => i18n('autocomplete.loading')}
          noOptionsMessage={() => i18n('autocomplete.noOptions')}
        />
      </div>
    );
  };

  // Retornar las tareas sucesoras de una tarea
  const taskSuccessors = (tareaId) => rows.find((r) => r.id === tareaId)?.successors || [];
  // Retornar las tareas predecesoras de una tarea
  const taskPredecessors = (tareaId) => rows.find((r) => r.id === tareaId)?.predecessors || [];

  // deseleccionar tareas sucesoras en cadena
  const deselectSuccessors = (tareaId, newRows) => {
    const successors = taskSuccessors(tareaId);
    for (const successor of successors) {
      newRows = newRows.map((row) => {
        if (row.id === successor) {
          row.selected = false;
        }
        return row;
      });
      newRows = deselectSuccessors(successor, newRows);
    }
    return newRows;
  };

  // seleccionar tareas predecesoras en cadena
  const selectPredecessors = (tareaId, newRows) => {
    const predecessors = taskPredecessors(tareaId);
    for (const predecessor of predecessors) {
      newRows = newRows.map((row) => {
        if (row.id === predecessor) {
          row.selected = true;
        }
        return row;
      });
      newRows = selectPredecessors(predecessor, newRows);
    }
    return newRows;
  };

  const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
  };

  const selectedTareas = () => rows.filter((r) => r.selected) || [];

  const [openModal, setOpenModal] = useState(false);

  const doCreateSuccess = (record) => {
    handleSelect({record});
    setOpenModal(false);
  };

  const onTaskModelUpdated = (record) => {
    const newRows = rows.map((r) => {
      if (r.id === record.id) {
        record.successors = r.successors;
        record.predecessors = r.predecessors;
        return record;
      }
      return r;
    });
    setRows(newRows);
    //handleChange(newRows); // no es necesario porque no cambia nada relacionado a dependencias
  };

  return (
    <div>
      {!isForm && (
        <ToolbarWrapper
          sx={{
            alignItems: 'center',
            mb: -2,
          }}>
          <DrawerButton
            drawerId={'DrawerPlanificadorTareaFormPage'}
            type={'button'}
            width={window.innerWidth * 0.7}
            disabled={!selectedTareas().length || loading}
            tooltipTitle={i18n('common.program')}
            buttonTitle={i18n('common.program')}
            buttonIcon={<CalendarTodayOutlined />}
            component={
              <PlanificadorTareaFormPage
                planificacion={{...getValues()}}
                rowsSelected={{
                  tareas: selectedTareas().map((t) => {
                    //remove unselected predecessors and successors
                    if (!dependency) return t;
                    const dep = dependency.find((d) => d.id === t.id);
                    return {
                      ...t,
                      predecessors: dep ? dep.predecessors?.filter((p) => selectedTareas().find((t) => t.id === p)) : [],
                      successors: dep ? dep.successors?.filter((p) => selectedTareas().find((t) => t.id === p)) : [],
                    };
                  }),
                }}
                drawer={true}
              />
            }
          />
          <CheckboxFormItem
            name="sequentialExecutionRequired"
            label={i18n('entities.planificacion.fields.sequentialExecutionRequired')}
            defaultValue={planificacion.sequentialExecutionRequired}
            onChange={(value) => {
              PlanificacionService.update(planificacion.id, {sequentialExecutionRequired: value});
            }}
          />
        </ToolbarWrapper>
      )}
      {!disabled && renderSelect()}
      {disabled && label && <div>{props.label}</div>}
      <Box
        style={{
          display: 'block',
          width: '100%',
          minHeight: !isForm ? 'calc(100vh - 250px)' : undefined,
          overflowX: 'auto',
        }}>
        <Table
          style={{
            borderRadius: '5px',
            border: '1px solid rgb(224, 224, 224)',
            borderCollapse: 'initial',
          }}>
          <TableHead>
            <TableRow>
              {!isForm && (
                <TableCellCustom padding="checkbox">
                  {hasRows && (
                    <Checkbox
                      disabled={disabled}
                      checked={Boolean(allSelected())}
                      onChange={() => toggleAllTareasSelected()}
                      size="small"
                    />
                  )}
                </TableCellCustom>
              )}
              <TableCellCustom name={'order'} label={'⠀N°'} padding="checkbox" />
              <TableCellCustom name={'titulo'} label={i18n('entities.modeloTarea.fields.titulo')} />
              <TableCellCustom name={'prioridad'} label={i18n('entities.modeloTarea.fields.prioridad')} />
              {/* <TableCellCustom
                    name={'tipoVisualizacion'}
                    label={i18n('entities.modeloTarea.fields.tipoVisualizacion')}
                  /> */}
              {/* <TableCellCustom label={i18n('entities.modeloTarea.fields.recursosNecesarios')} /> */}
              <TableCellCustom customStyle={{minWidth: 220}} label={i18n('common.predecessors')} />
              <TableCellCustom size="md" />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={100}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!loading && !hasRows && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{
                    borderBottom: '0px solid white',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {i18n('table.noDataAdded')}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              hasRows &&
              rows.map((row) => (
                <TableRow key={row.id}>
                  {!isForm && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={disabled}
                        checked={Boolean(row.selected)}
                        onChange={() => toggleTareaSelected(row.id)}
                        size="small"
                      />
                    </TableCell>
                  )}
                  <TableCell padding="checkbox">
                    {'⠀'}
                    {row.order}
                  </TableCell>
                  <TableCell>{row.titulo}</TableCell>
                  <TableCell>
                    <ClasificacionTagView value={row.prioridad} entity="modeloTarea" fieldName="prioridad" />
                  </TableCell>
                  {/* <TableCell>{row.tipoVisualizacion ? i18n(`entities.modeloTarea.enumerators.tipoVisualizacion.${row.tipoVisualizacion}`) : null}</TableCell> */}
                  {/* <TableCell>
                    {row.recursosNecesarios?.length > 0 && !isString(row.recursosNecesarios[0]) && <RecursoListItem value={row.recursosNecesarios} />}
                  </TableCell> */}

                  <TableCell>{renderSelectPredecessors(row.id)}</TableCell>

                  <TableCell>
                    <Box display="flex" justifyContent="flex-end">
                      {!disabled && (
                        <Tooltip followCursor title={i18n('common.moveUp')}>
                          <IconButton onClick={() => moveUp(row.id)}>
                            <MoveUpIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!disabled && (
                        <Tooltip followCursor title={i18n('common.moveDown')}>
                          <IconButton onClick={() => moveDown(row.id)}>
                            <MoveDownIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <DrawerButton
                        drawerId={'DrawerModeloTareaViewPage'}
                        type={'icon'}
                        tooltipTitle={i18n('common.view')}
                        buttonTitle={i18n('common.view')}
                        buttonIcon={<SearchIcon />}
                        width={window.innerWidth * 0.55}
                        component={<ModeloTareaView record={row} drawer={true} />}
                      />
                      <DrawerButton
                        drawerId={'DrawerModeloTareaFormPage'}
                        type={'icon'}
                        tooltipTitle={i18n('common.edit')}
                        buttonTitle={i18n('common.edit')}
                        buttonIcon={<EditIcon />}
                        width={window.innerWidth * 0.55}
                        component={<ModeloTareaFormPage updated={onTaskModelUpdated} record={row} drawer={true} />}
                      />
                      {!disabled && (
                        <Tooltip followCursor title={i18n('common.remove2')}>
                          <IconButton color="secondary" onClick={() => remove(row.id)}>
                            <RemoveIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      {openModal && <ModeloTareaFormModal onClose={() => setOpenModal(false)} onSuccess={doCreateSuccess} />}
    </div>
  );
};

export default PlanificacionDependencyView;
