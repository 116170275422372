import PropTypes from 'prop-types';
import {useRef} from 'react';

import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import moment from 'moment-timezone';
import {useTranslation} from 'react-i18next';
import QRCode from 'react-qr-code';
import {useSelector} from 'react-redux';
import {useReactToPrint} from 'react-to-print';
import config from 'src/config';
import {i18n} from 'src/i18n';
import {default as authSelectors, default as selectors} from 'src/modules/auth/authSelectors';
import ActivoListItem from 'src/view/activo/list/ActivoListItem';
import ModeloTareaListItem from 'src/view/modeloTarea/list/ModeloTareaListItem';
import PuestoListItem from 'src/view/puesto/list/PuestoListItem';
//import TableWrapper from 'src/view/shared/table/styles/TableWrapper';
import FilesViewItem from 'src/view/shared/view/FilesViewItem';
import UbicacionListItem from 'src/view/ubicacion/list/UbicacionListItem';
import UserListItem from 'src/view/user/list/UserListItem';

const BoxWrapper = styled(Box)(
  ({theme}) => `
  border-radius: ${theme.general.borderRadius};
  background: ${theme.colors.alpha.black[5]};
`,
);

const TableWrapper = styled(Box)(
  ({theme}) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
`,
);

const LogoWrapper = styled(Box)(
  () => `
    width: '52px'
`,
);

const Body = (props) => {
  const {record} = props;
  const {t} = useTranslation();
  const logoUrl = useSelector(selectors.selectLogoUrl);
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const itemsList = [
    {
      id: 1,
      name: 'Design services for March',
      quantity: 1,
      price: 8945,
      currency: '$',
    },
    {
      id: 2,
      name: 'Website migration services',
      quantity: 3,
      price: 2367,
      currency: '$',
    },
  ];

  const getTotal = (items) => {
    let total = 0;

    items?.map((item) => {
      if (item.tarea?.recursosNecesarios) {
        total =
          total +
          item.tarea?.recursosNecesarios?.reduce(function (a, b) {
            return a + b['total'];
          }, 0) *
            (moment(item.finProgramacion).diff(item.inicioProgramacion, item.tipoPeriodicidad || 'days') === 0
              ? 1
              : moment(item.finProgramacion).diff(item.inicioProgramacion, item.tipoPeriodicidad || 'days'));
      }
    });

    return new Intl.NumberFormat('es-CL', {
      currency: 'CLP',
      style: 'decimal',
    }).format(total);
  };

  return (
    <Container ref={printRef} maxWidth={false}>
      <Card sx={{p: 3, mb: 3}}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <QRCode
                value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/planificador-tarea/${record?.id}`}
                size={140}
              />
            </Grid>
            <Typography variant="h1" gutterBottom>
              {t('Planificación ')}
              {i18n(`entities.planificacion.enumerators.clasificacion.${record.planificacion?.clasificacion}`)}
            </Typography>
            <Typography variant="h2" color="text.secondary">
              {record.planificacion?.nombre}
            </Typography>
            <Typography sx={{py: 2}} variant="h3" color="text.secondary">
              Planificada por <UserListItem value={record.createdBy} />
              <UserListItem value={record.planificacion?.createdBy} />
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <LogoWrapper>
              {logoUrl ? (
                <img src={logoUrl} width="84px" alt={i18n('app.title')} style={{verticalAlign: 'middle'}} />
              ) : (
                <>{currentTenant?.name}</>
              )}
            </LogoWrapper>

            <Typography sx={{pt: 3, pb: 1}} variant="h4">
              {i18n(`entities.planificacion.fields.documentosAdjuntos`)}
            </Typography>

            {record.planificacion?.documentosAdjuntos && record.planificacion?.documentosAdjuntos.length > 0 && (
              <FilesViewItem value={record.planificacion?.documentosAdjuntos} />
            )}
          </Box>
        </Box>
        <Divider sx={{my: 4}} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {record.activo && (
              <>
                <Typography variant="subtitle2" gutterBottom>
                  {t('Activo Relacionado')}:
                </Typography>
                <ActivoListItem value={record.activo} />
                {record.activo.localizacion && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      {t('Localización Activo')}:
                    </Typography>
                    <UbicacionListItem value={record.localizacion} />
                  </>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={4}
              justifyContent={{
                xs: 'flex-start',
                sm: 'flex-end',
              }}>
              <Grid item>
                <Typography variant="subtitle2" gutterBottom>
                  {i18n(`entities.planificacion.fields.fechaEstimadaInicio`)}
                </Typography>
                <Typography sx={{pb: 2}} variant="h5">
                  {moment(record.planificacion?.fechaEstimadaInicio).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" gutterBottom>
                  {i18n(`entities.planificacion.fields.fechaEstimadaFin`)}
                </Typography>
                <Typography sx={{pb: 2}} variant="h5">
                  {moment(record.planificacion?.fechaEstimadaFin).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <BoxWrapper textAlign="right" mt={1} p={3}>
              <Typography component="span" variant="h4" fontWeight="normal">
                {i18n(`entities.planificacion.fields.presupuestoEstimado`)}:
              </Typography>
              <Typography component="span" variant="h4">
                {new Intl.NumberFormat('es-CL', {
                  currency: 'CLP',
                  style: 'decimal',
                }).format(' ' + record.planificacion?.presupuestoEstimado)}
              </Typography>
            </BoxWrapper>
          </Grid>
        </Grid>

        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Recursos Unitarios Estimados')}</TableCell>
                  <TableCell>{t('Total Tareas')}</TableCell>
                  <TableCell>{t('Recursos Estimados')}</TableCell>
                  <TableCell>{t('Tarea')}</TableCell>
                  <TableCell>{t('Aceptadores')}</TableCell>
                  <TableCell>{t('Validadores')}</TableCell>
                  <TableCell>{t('Ejecutores')}</TableCell>
                  <TableCell>{t('Puestos')}</TableCell>
                  <TableCell>{t('Programación')}</TableCell>
                  <TableCell>{t('Tiempo Limite Tarea')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.programacionTareas?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item.tarea?.recursosNecesarios
                        ? new Intl.NumberFormat('es-CL', {
                            currency: 'CLP',
                            style: 'decimal',
                          }).format(
                            item.tarea?.recursosNecesarios?.reduce(function (a, b) {
                              return a + b['total'];
                            }, 0),
                          )
                        : 'No'}
                    </TableCell>
                    <TableCell>
                      {moment(item.finProgramacion).diff(item.inicioProgramacion, item.tipoPeriodicidad) === 0
                        ? 1
                        : moment(item.finProgramacion).diff(item.inicioProgramacion, item.tipoPeriodicidad || 'days') + 1}
                    </TableCell>
                    <TableCell>
                      {item.tarea?.recursosNecesarios
                        ? new Intl.NumberFormat('es-CL', {
                            currency: 'CLP',
                            style: 'decimal',
                          }).format(
                            item.tarea?.recursosNecesarios?.reduce(function (a, b) {
                              return a + b['total'];
                            }, 0) *
                              (moment(item.finProgramacion).diff(item.inicioProgramacion, item.tipoPeriodicidad || 'days') === 0
                                ? 1
                                : moment(item.finProgramacion).diff(item.inicioProgramacion, item.tipoPeriodicidad || 'days')),
                          )
                        : 'No'}
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>
                        <ModeloTareaListItem value={item.tarea} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <UserListItem value={item.aceptadores} />
                    </TableCell>
                    <TableCell>
                      <UserListItem value={item.validadores} />
                    </TableCell>
                    <TableCell>
                      <UserListItem value={item.ejecutores} />
                    </TableCell>
                    <TableCell>
                      <PuestoListItem value={item.puestos} />
                    </TableCell>
                    <TableCell>
                      {i18n('common.start')} {moment(item.inicioProgramacion).format('DD/MM/YYYY')}
                      {` ${i18n('common.end')} `}
                      {moment(item.finProgramacion).format('DD/MM/YYYY')}{' '}
                      {item.periodicidad &&
                        item.tipoPeriodicidad &&
                        ' cada ' +
                          item.periodicidad +
                          ' ' +
                          i18n(`entities.planificadorTarea.enumerators.tipoPeriodicidad.${item.tipoPeriodicidad}`)}
                    </TableCell>

                    <TableCell>
                      {item.tiempoLimite && item.tipoTiempoLimite
                        ? item.tiempoLimite +
                          ' ' +
                          i18n(`entities.planificadorTarea.enumerators.tipoTiempoLimite.${item.tipoTiempoLimite}`)
                        : 'No'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={0}></TableCell>
                  <TableCell colSpan={10} align="right">
                    <Typography gutterBottom variant="caption" color="text.secondary" fontWeight="bold">
                      {t('Total Estimado')}:
                    </Typography>
                    <Typography variant="h3" fontWeight="bold">
                      {getTotal(record.programacionTareas)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </TableWrapper>
        <Typography variant="subtitle2" gutterBottom>
          {t('Firmas')}
        </Typography>
        <Typography sx={{pb: 4}} variant="body2">
          Estas son las firmas asociadas con la planificacion
        </Typography>

        <Grid container>
          {record.firmas.map((raw) => {
            return (
              <Grid item lg={3}>
                <Grid style={{marginBottom: 10}} container direction="column">
                  <Grid item>
                    <label
                      style={{
                        color: 'gray',
                        fontWeight: 600,
                        fontSize: 20,
                      }}>
                      {raw.responsable?.fullName} {' - '}
                      {raw.objetivo}
                    </label>
                  </Grid>

                  <Grid
                    container
                    style={{
                      border: '10px solid gray',
                      borderRadius: '5px',
                    }}>
                    <img
                      alt={'Firma ' + raw.responsable?.fullName}
                      style={{
                        width: '100%',
                        height: 150,
                      }}
                      src={raw.firma}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
          <Button onClick={handlePrint} variant="contained" sx={{mx: 2}} startIcon={<DownloadTwoToneIcon />}>
            {t('Imprimir Planificación')}
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

Body.propTypes = {
  // @ts-ignore
  invoice: PropTypes.object.isRequired,
};

export default Body;
